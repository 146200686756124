import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Select } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import dayChart from "./configs/dailyLogins";

function DailyUserLoginChart() {
	const { Title } = Typography;
	const { Option } = Select;

	// Default immer aktueller Tag von new Date
	const [selectedDay, setSelectedDay] = useState(
		new Date().toLocaleDateString("de-DE", { weekday: "long" })
	);

	const handleDayChange = (value) => {
		setSelectedDay(value);
	};

	return (
		<>
			<div className='linechart'>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Title level={5}>Nutzeraktivität pro Tag</Title>
				</div>
				<Select
					defaultValue={selectedDay}
					onChange={handleDayChange}
					style={{ width: 120 }}
				>
					<Option value='Montag'>Montag</Option>
					<Option value='Dienstag'>Dienstag</Option>
					<Option value='Mittwoch'>Mittwoch</Option>
					<Option value='Donnerstag'>Donnerstag</Option>
					<Option value='Freitag'>Freitag</Option>
					<Option value='Samstag'>Samstag</Option>
					<Option value='Sonntag'>Sonntag</Option>
				</Select>
			</div>

			<ReactApexChart
				className='full-width'
				options={dayChart.options}
				series={dayChart.series}
				type='area'
				height={350}
				width={"100%"}
			/>
			<div className='sales'>
				<ul>
					<li>{<MinusOutlined />} Paid Accounts</li>
					<li>{<MinusOutlined />} Free Accounts</li>
				</ul>
			</div>
		</>
	);
}

export default DailyUserLoginChart;

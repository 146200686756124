import React, { useState } from "react";
import { Input, Tag, message } from "antd";

const TagInput = ({ tags, setTags }) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        const trimmedValue = inputValue.trim();

        // Tag hinzufügen, wenn es noch nicht vorhanden ist und nicht leer
        if (trimmedValue && !tags.includes(trimmedValue)) {
            setTags([...tags, trimmedValue]);
        } else if (!trimmedValue) {
            message.error("Das Tag darf nicht leer sein.");
        } else {
            message.error("Dieses Tag existiert bereits.");
        }

        setInputValue(""); // Eingabefeld leeren
    };

    const removeTag = (removedTag) => {
        // Wichtig: Das Tag sicher filtern und prüfen, ob es exakt übereinstimmt
        const updatedTags = tags.filter((tag) => tag !== removedTag);
        setTags(updatedTags); // Tags aktualisieren
    };

    return (
        <div>
            <Input
                type="text"
                value={inputValue}
                placeholder="Tag hinzufügen"
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}  // Bei Enter Tag hinzufügen
                onBlur={handleInputConfirm}  // Bei Verlassen Tag hinzufügen
                style={{ width: '100%', marginBottom: '10px' }}
            />
            <div>
                {tags.map((tag, index) => (
                    <Tag
                        key={index}
                        closable
                        onClose={(e) => {
                            e.preventDefault(); // Verhindert doppeltes Ausführen
                            removeTag(tag);
                        }}
                    >
                        {tag}
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default TagInput;
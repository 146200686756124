import fetch from "../interceptor/fetchInterceptor";

export function addCategory(postData) {
	return fetch({
		url: "/categories",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function getCategories() {
	return fetch({
		url: "/categories",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function updateCategory(id, postData) {
	return fetch({
		url: `/categories/${id}`,
		method: "patch",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function deleteCategory(id) {
	return fetch({
		url: `/categories/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}

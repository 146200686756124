import React, { useState, useEffect } from "react";
import { Table, Button, Input, Row, Col } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";

const ContentList = ({ allContent, onEdit, onDelete }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredContent, setFilteredContent] = useState(allContent);

  useEffect(() => {
    // Ensure the content is set correctly when allContent updates (e.g., after reload)
    setFilteredContent(allContent);
  }, [allContent]);

  // Handle search input
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = allContent.filter(
      (content) =>
        content.title.toLowerCase().includes(value) ||
        content.type.toLowerCase().includes(value) ||
        content.categoryRequirements.some((category) =>
          category.category.title.toLowerCase().includes(value)
        )
    );
    setFilteredContent(filteredData);
  };

  // Extract unique category titles for filter options
  const categoryFilters = [
    ...new Set(
      allContent.flatMap((content) =>
        content.categoryRequirements.map((category) => category.category.title)
      )
    ),
  ].map((title) => ({ text: title, value: title }));

  const columns = [
    {
      title: "Titel",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 300, // Breiter für den Titel
    },
    {
      title: "Content Typ",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Kategorien",
      dataIndex: "categoryRequirements",
      key: "categories",
      filters: categoryFilters, // Filter nach Kategorien
      onFilter: (value, record) =>
        record.categoryRequirements.some((category) =>
          category.category.title.includes(value)
        ),
      render: (categoryRequirements) =>
        categoryRequirements.map((category, index) => (
          <span key={index}>
            {category.category.title} ({category.minLevel} - {category.maxLevel})
            {index < categoryRequirements.length - 1 && ", "}
          </span>
        )),
    },
    {
      title: "Aktionen",
      key: "actions",
      width: 150, // Kleiner für die Aktionen
      render: (_, content) => (
        <>
          <Button type="primary" onClick={() => onEdit(content)}>
            Bearbeiten
          </Button>
          <Button
            danger
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onDelete(content.id)}
          >
            <DeleteFilled />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col xs={8}>
          <Input
            placeholder="Suche nach Titel, Typ oder Kategorie"
            value={searchText}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredContent}
        pagination={{ pageSize: 10 }}
        rowKey={(content) => content.id}
      />
    </>
  );
};

export default ContentList;

import React, { useEffect, useState } from "react";
import { Row, Col, Card, Input, Button, Typography, message } from "antd";
import { addTag, deleteTag, getTags, updateTag } from "../services/TagsService";
import TagList from "../components/Tag/TagList";

const { Title } = Typography;

const TagsEditor = () => {
	const [tagData, setTagData] = useState({
		id: null,
		title: "",
		description: "",
		socialBatteryValue: null,
	});
	const [tags, setTags] = useState([]);
	const [errors, setErrors] = useState({ title: "" });
	const [isEditMode, setIsEditMode] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setTagData({
			...tagData,
			[name]: name === 'socialBatteryValue' ? parseInt(value, 10) || '' : value,
		});

		if (name === "title") {
			if (!value.trim()) {
				setErrors({ ...errors, title: "Titel ist erforderlich" });
			} else {
				setErrors({ ...errors, title: "" });
			}
		}
	};

	const handleSave = async () => {
		try {
			if (isEditMode) {
				const response = await updateTag(tagData.id, tagData);
				if (response) {
					message.success("Tag erfolgreich geupdated");
				}
			} else {
				delete tagData.id;

				const response = await addTag(tagData);
				if (response) {
					message.success("Tag erfolgreich erstellt");
				}
			}
			setTagData({ id: null, title: "", description: "", socialBatteryValue: null });
			setIsEditMode(false);
			fetchTags();
		} catch (error) {
			console.error(error);
		}
	};

	const isTitleValid = tagData.title.trim().length > 0;

	const fetchTags = async () => {
		const res = await getTags();
		setTags(res);
	};

	const handleEdit = (tag) => {
		setTagData({
			id: tag.id,
			title: tag.title,
			description: tag.description,
			socialBatteryValue: tag.socialBatteryValue,
		});
		setIsEditMode(true);
	};

	const handleDelete = async (id) => {
		await deleteTag(id);
		message.success("Tag gelöscht");
		fetchTags();
	};

	useEffect(() => {
		fetchTags();
	}, []);

	return (
		<div className='layout-content'>
			<Row gutter={[24, 0]}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox '>
						<div>
							<Title level={3}>Tags Editor</Title>
							<Row align='middle' gutter={[24, 0]}>
								<Col xs={18}>
									<Title level={5}>
										<span style={{ color: "red" }}>* </span>Tag Bezeichnung
									</Title>
									<Input
										placeholder='Tag Bezeichnung eingeben'
										name='title'
										value={tagData.title}
										onChange={handleInputChange}
										style={{ marginBottom: "4px" }}
										required
									/>
									{errors.title && (
										<span style={{ color: "red" }}>{errors.title}</span>
									)}
								</Col>
								<Col xs={6}>
									{/* number input int positive or negative socialBatteryValue */}
									<Title level={5}>
										<span style={{ color: "red" }}>* </span>Sozial Batterie Wert
									</Title>
									<Input
										placeholder='Sozial Batterie Wert eingeben'
										name='socialBatteryValue'
										value={tagData.socialBatteryValue}
										onChange={handleInputChange}
										style={{ marginBottom: "4px" }}
										required
										type='number'
									/>
								</Col>
								<Col xs={24}>
									<Title level={5} style={{ marginTop: "10px" }}>
										Tag Beschreibung (Optional)
									</Title>
									<Input.TextArea
										placeholder='Beschreibung eingeben (optional)'
										name='description'
										value={tagData.description}
										onChange={handleInputChange}
										style={{ marginBottom: "10px" }}
									/>
								</Col>
							</Row>
						</div>
						<Button
							type='primary'
							onClick={handleSave}
							disabled={!isTitleValid}
							block
							style={{ marginTop: "20px" }}
						>
							{isEditMode ? "Update Tag" : "Tag speichern"}
						</Button>
					</Card>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<TagList tags={tags} onEdit={handleEdit} onDelete={handleDelete} />
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default TagsEditor;

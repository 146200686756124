import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

const { Title } = Typography;

const AchievementList = ({ allAchievements, onEdit, onDelete }) => {
	return (
		<>
			<Row gutter={[24, 0]} style={{ marginBottom: "10px" }}>
				<Col span={10}>
					<Title level={5}>Name</Title>
				</Col>
				<Col span={10}>
					<Title level={5}>Beschreibung</Title>
				</Col>
				<Col span={2}>
					<Title level={5}></Title>
				</Col>
				<Col span={2}>
					<Title level={5}></Title>
				</Col>
			</Row>

			<List
				dataSource={allAchievements}
				renderItem={(achievement, index) => (
					<List.Item key={index}>
						<Row gutter={[24, 0]} style={{ width: "100%" }}>
							<Col span={10}>
								<strong>{achievement.title}</strong>
							</Col>
							<Col span={10}>{achievement.description || 'Keine Beschreibung vorhanden'}</Col>
                            <Col span={2}>
								<Button type='primary'
                                    onClick={() => onEdit(achievement)}
                                >
									Bearbeiten
								</Button>
							</Col>
							<Col span={2}>
								<Button danger type='primary'
                                    onClick={() => onDelete(achievement.id)}
                                >
									<DeleteFilled style={{width: 12}}/>
								</Button>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		</>
	);
};

export default AchievementList;

import fetch from "../interceptor/fetchInterceptor";

export function addBusinessRule(postData) {
  return fetch({
    url: "/business-rules",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function getBusinessRules() {
  return fetch({
    url: "/business-rules",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

export function updateBusinessRule(id, postData) {
  return fetch({
    url: `/business-rules/${id}`,
    method: "put",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function deleteBusinessRule(id) {
  return fetch({
    url: `/business-rules/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
}

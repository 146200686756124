import React, { useEffect, useState } from "react";
import { Row, Col, Card, Input, Button, Typography, message } from "antd";
import { addUserLevel, deleteUserLevel, getUserLevel, updateUserLevel } from "../services/UserLevelService";
import UserLevelList from "../components/UserLevel/UserLevelList";

const { Title } = Typography;

const UserLevel = () => {
	const [levelNumber, setLevelNumber] = useState("");
	const [minXp, setMinXp] = useState("");
	const [userLevelId, setUserLevelId] = useState("");
	const [isEditMode, setIsEditMode] = useState(false);
	const [allUserLevel, setAllUserLevel] = useState([]);

	const isTitleValid = levelNumber !== "" && minXp !== "";

    const handleEdit = (level) => {
        setUserLevelId(level.id);
        setLevelNumber(+level.userLevel);
        setMinXp(+level.minXP);
		setIsEditMode(true);
	};

	const handleSave = async () => {
		if (!isTitleValid) {
			message.error("Level und XP müssen angegeben werden.");
			return;
		}

        if (isEditMode) {
            const postData = {
                userLevel: +levelNumber,
                minXP: +minXp,
            };
            await updateUserLevel(userLevelId, postData);
            message.success(`Level erfolgreich ${isEditMode ? "aktualisiert" : "hinzugefügt"}!`);
        } else {
            const postData = {
                userLevel: +levelNumber,
                minXP: +minXp,
            }

            await addUserLevel(postData);
            message.success(`Level erfolgreich ${isEditMode ? "aktualisiert" : "hinzugefügt"}!`);
        }
		
		setLevelNumber("");
		setMinXp("");
		setIsEditMode(false);
        fetchAllUserLevel();
	};

    const handleDelete = async (id) => {
		await deleteUserLevel(id);
		message.success("Level entfernt");
		fetchAllUserLevel();
	};

    const fetchAllUserLevel = async () => {
        const res = await getUserLevel();
        setAllUserLevel(res);
    }

    useEffect(() => {
        fetchAllUserLevel();
    }, []);

	return (
		<div className="layout-content">
			<Row gutter={[24, 0]}>
				<Col xs={24}>
					<Card bordered={false} className="criclebox">
						<div>
							<Title level={3}>User Level (XP)</Title>
							<Row align="middle" gutter={[24, 0]}>
								<Col xs={24} sm={12} lg={8}>
									<Input
										type="number"
										placeholder="Level angeben"
										value={levelNumber}
										onChange={(e) => setLevelNumber(e.target.value)}
									/>
								</Col>
								<Col xs={24} sm={12} lg={8}>
									<Input
										type="number"
										placeholder="Notwendige XP angeben"
										value={minXp}
										onChange={(e) => setMinXp(e.target.value)}
									/>
								</Col>
							</Row>
						</div>
						<Button
							type="primary"
							onClick={handleSave}
							disabled={!isTitleValid}
							block
							style={{ marginTop: "20px" }}
						>
							{isEditMode ? "Level aktualisieren" : "Level hinzufügen"}
						</Button>
					</Card>
				</Col>
			</Row>

            <Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<UserLevelList allUserLevel={allUserLevel} onEdit={handleEdit} onDelete={handleDelete} />
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default UserLevel;

import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

const { Title } = Typography;

const TagList = ({ tags, onEdit, onDelete }) => {
	return (
		<>
			<Row gutter={[24, 0]} style={{ marginBottom: "10px" }}>
				<Col span={8}>
					<Title level={5}>Tag Bezeichnung</Title>
				</Col>
				<Col span={12}>
					<Title level={5}>Tag Beschreibung</Title>
				</Col>
				<Col span={4}>
					<Title level={5}></Title>
				</Col>
			</Row>

			<List
				dataSource={tags}
				renderItem={(tag, index) => (
					<List.Item key={index}>
						<Row gutter={[24, 0]} style={{ width: "100%" }}>
							<Col span={8}>
								<strong>{tag.title}</strong>
							</Col>
							<Col span={12}>{tag.description || "Keine Beschreibung"}</Col>
							<Col span={2}>
								<Button type='primary' onClick={() => onEdit(tag)}>
									Bearbeiten
								</Button>
							</Col>
							<Col span={2}>
								<Button danger type='primary' onClick={() => onDelete(tag.id)}>
									<DeleteFilled style={{width: 12}} />
								</Button>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		</>
	);
};

export default TagList;

import React, { useState, useEffect } from "react";
import { Input, Select, Button, Typography, message, Card, Row, Col, Checkbox } from "antd";
import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	DeleteOutlined,
	PlusOutlined,
} from "@ant-design/icons";
import { addTaskflow, updateTaskflow } from "../../services/TaskflowService";
import CategorySelectionForTaskflow from "../Rules/CategorySelectionForTaskflow";
import TagInput from "./TagInput";

const { Title } = Typography;
const { Option } = Select;

const TaskFlowForm = ({
	currentTaskFlow,
	setCurrentTaskFlow,
	categories,
	onShowTaskModal,
	onSave,
	fetchTaskflows,
	editMode,
	handleEditTask,
	setEditIndex,
	tagOptions,
}) => {
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [errors, setErrors] = useState({});
	const [results, setResults] = useState([]);

	const taskFlowTypes = ["TRAINING", "QUIZ", "SINGLE_TASK"];

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setCurrentTaskFlow((prevState) => ({
			...prevState,
			[name]: value,
		}));

		// Input validation
		switch (name) {
			case "title":
				if (!value.trim()) {
					setErrors((prevErrors) => ({
						...prevErrors,
						title: "Name muss angegeben werden.",
					}));
				} else {
					setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
				}
				break;
			case "taskFlowType":
				if (!value) {
					setErrors((prevErrors) => ({
						...prevErrors,
						type: "Typ muss ausgewählt werden.",
					}));
				} else {
					setErrors((prevErrors) => ({ ...prevErrors, type: "" }));
				}
				break;
			case "xp":
				if (value === "") {
					setErrors((prevErrors) => ({
						...prevErrors,
						xp: "Zu verdienende XP müssen angegeben werden.",
					}));
				} else {
					setErrors((prevErrors) => ({ ...prevErrors, xp: "" }));
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		// Clear errors when currentTaskFlow title changes
		if (currentTaskFlow.title) {
			setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
		}

		// set default Taskflow type to TRAINING
		if (!currentTaskFlow.taskFlowType) {
			setCurrentTaskFlow((prevState) => ({
				...prevState,
				taskFlowType: "TRAINING",
			}));
		}

		// set results if set
		if (currentTaskFlow.results) {
			setResults(currentTaskFlow.results);
		}

		if (currentTaskFlow.userExperiencePointsToEarn)
			setCurrentTaskFlow((prevState) => ({
				...prevState,
				xp: currentTaskFlow.userExperiencePointsToEarn,
			}));

		if (currentTaskFlow.categories) {
			setSelectedCategories(currentTaskFlow.categories);
		}
	}, [
		currentTaskFlow.categories,
		currentTaskFlow.results,
		currentTaskFlow.taskFlowType,
		currentTaskFlow.title,
		currentTaskFlow.userExperiencePointsToEarn,
		setCurrentTaskFlow,
	]);

	const validateForm = () => {
		const newErrors = {};
		if (!currentTaskFlow.title) newErrors.title = "Der Name ist verpflichtend.";
		if (!currentTaskFlow.taskFlowType) newErrors.type = "Der Typ ist verpflichtend.";
		if (currentTaskFlow.xp == null) newErrors.xp = "Zu verdienende XP sind verpflichtend.";

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0; // Returns true if there are no errors
	};

	const setMatchingParameter = (tags) => {
		setCurrentTaskFlow({ ...currentTaskFlow, matchingParameters: tags });
	};

	const handleSave = () => {
		if (!validateForm()) {
			return; // Stop saving if validation fails
		}

		const { type, xp, ...taskFlowData } = {
			...currentTaskFlow,
			userExperiencePointsMin: parseInt(currentTaskFlow.userExperiencePointsMin, 10) || 0,
			userExperiencePointsMax: parseInt(currentTaskFlow.userExperiencePointsMax, 10) || 0,
			userExperiencePointsToEarn: parseInt(currentTaskFlow.xp, 10) || 0,

			categories: selectedCategories.map((category) => ({
				categoryId: category.id,
				taskTitle: category.title,
				minLevel: category.min,
				maxLevel: category.max,
				valueToEarn: category.pointsToEarn,
			})),

			requiredTags: Array.isArray(currentTaskFlow.requiredTags)
				? currentTaskFlow.requiredTags
				: [],
			tagsToEarn: Array.isArray(currentTaskFlow.tagsToEarn) ? currentTaskFlow.tagsToEarn : [],
			taskIds: Array.isArray(currentTaskFlow.taskIds) ? currentTaskFlow.taskIds : [],
			taskFlowType: currentTaskFlow.taskFlowType || "Single Task",

			results: currentTaskFlow.results?.map((result) => ({
				...result,
				categories: result.categories?.map((category) => ({
					id: category.id,
					pointsToEarn: category.pointsToEarn, // Punkte auch hier hinzufügen, falls erforderlich
				})), // Stelle sicher, dass du die Kategorien korrekt speicherst
			})),
		};

		// Add if create or update when edit
		if (editMode) {
			updateTaskflow(taskFlowData)
				.then((response) => {
					message.success("Taskflow aktualisiert");
					onSave();
					// Clear form after saving
					clearCurrentTaskFlow();
				})
				.catch((error) => {
					message.error("Fehler beim Aktualisieren des Taskflows");
					console.error("Error updating TaskFlow:", error);
				});
		} else {
			addTaskflow(taskFlowData)
				.then((response) => {
					message.success("Taskflow hinzugefügt");
					fetchTaskflows();
					// Clear form after saving
					clearCurrentTaskFlow();
				})
				.catch((error) => {
					message.error("Fehler beim Speichern des Taskflows");
					console.error("Error saving TaskFlow:", error);
				});
		}
	};

	// Task nach oben verschieben
	const moveTaskUp = (index) => {
		if (index === 0) return; // Wenn es die erste Aufgabe ist, nicht verschieben
		const updatedTasks = [...currentTaskFlow.tasks];
		[updatedTasks[index - 1], updatedTasks[index]] = [
			updatedTasks[index],
			updatedTasks[index - 1],
		];
		setCurrentTaskFlow((prev) => ({ ...prev, tasks: updatedTasks }));
	};

	// Task nach unten verschieben
	const moveTaskDown = (index) => {
		if (index === currentTaskFlow.tasks.length - 1) return; // Wenn es die letzte Aufgabe ist, nicht verschieben
		const updatedTasks = [...currentTaskFlow.tasks];
		[updatedTasks[index + 1], updatedTasks[index]] = [
			updatedTasks[index],
			updatedTasks[index + 1],
		];
		setCurrentTaskFlow((prev) => ({ ...prev, tasks: updatedTasks }));
	};

	// Neues Ergebnis hinzufügen
	const addResult = () => {
		setResults([
			...results,
			{
				name: "",
				description: "",
				affirmations: "",
				tags: [],
				categories: null,
				matchingParameters: {},
			},
		]);
	};

	const handleResultChange = (index, field, value) => {
		const updatedResults = [...results];
		updatedResults[index][field] = value;
		setResults(updatedResults);
		setCurrentTaskFlow({ ...currentTaskFlow, results: updatedResults });
	};

	const handleMatchingParameterChange = (index, paramKey, value) => {
		const updatedResults = [...results];
		updatedResults[index].matchingParameters[paramKey] = value;
		setResults(updatedResults);
		setCurrentTaskFlow({ ...currentTaskFlow, results: updatedResults });
	};

	const removeResult = (index) => {
		const updatedResults = results.filter((_, i) => i !== index);
		setResults(updatedResults);
		setCurrentTaskFlow({ ...currentTaskFlow, results: updatedResults });
	};

	const clearCurrentTaskFlow = () => {
		setCurrentTaskFlow({
			title: "",
			description: "",
			requiredTimeForTaskFlow: null,
			taskFlowType: null,
			tasks: [],
			xp: 0,
			categories: [],
		});

		setResults([]);
		setErrors({});
		setSelectedCategories([]);
		setEditIndex(null);
	};

	return (
		<div>
			<Row gutter={12}>
				<Col xs={18}>
					<Title level={5}>
						<span style={{ color: "red" }}>*</span> TaskFlow Name
					</Title>
					<Input
						name='title'
						placeholder='Bezeichnung angeben'
						value={currentTaskFlow.title}
						onChange={handleInputChange}
						style={{ marginBottom: "4px" }}
					/>
					{errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
				</Col>
				{/* <Col xs={6}>
					<Title level={5}>
						<span style={{ color: "red" }}>*</span> Intro Text
					</Title>
					<Input
						name='introText'
						placeholder='Intro Text angeben'
						value={currentTaskFlow.introText}
						onChange={(e) =>
							setCurrentTaskFlow({ ...currentTaskFlow, introText: e.target.value })
						}
						style={{ marginBottom: "10px" }}
					/>
				</Col> */}
				<Col xs={6}>
					<Title level={5}>
						<span style={{ color: "red" }}>*</span> Dauer für Taskflow angeben
					</Title>
					<Input
						name='requiredTimeForTaskFlow'
						placeholder='Dauer angeben'
						value={currentTaskFlow.requiredTimeForTaskFlow}
						onChange={(e) =>
							setCurrentTaskFlow({
								...currentTaskFlow,
								requiredTimeForTaskFlow: e.target.value,
							})
						}
						style={{ marginBottom: "10px" }}
					/>
				</Col>
			</Row>

			<Title level={5} style={{ marginTop: "10px" }}>
				Beschreibung
			</Title>
			<Input.TextArea
				placeholder='Beschreibung angeben'
				value={currentTaskFlow.description}
				onChange={(e) =>
					setCurrentTaskFlow({ ...currentTaskFlow, description: e.target.value })
				}
				style={{ marginBottom: "10px" }}
			/>

			<Title level={5}>
				<span style={{ color: "red" }}>*</span> Typ
			</Title>
			<Select
				name='taskFlowType'
				placeholder='TaskFlow Typ auswählen'
				value={currentTaskFlow.taskFlowType}
				onChange={(value) => {
					setCurrentTaskFlow({ ...currentTaskFlow, taskFlowType: value });
					if (!value) {
						setErrors((prevErrors) => ({ ...prevErrors, type: "Type is required." }));
					} else {
						setErrors((prevErrors) => ({ ...prevErrors, type: "" }));
					}
				}}
				style={{ width: "100%", marginBottom: "10px" }}
			>
				{taskFlowTypes.map((type) => (
					<Option key={type} value={type}>
						{type}
					</Option>
				))}
			</Select>
			{errors.type && <span style={{ color: "red" }}>{errors.type}</span>}

			<Title level={5}>Mindestens erforderliche XP</Title>
			<Input
				name='userExperiencePointsMin'
				type='number'
				placeholder='Mindest XP angeben'
				value={currentTaskFlow.userExperiencePointsMin}
				onChange={handleInputChange}
				style={{ marginBottom: "10px" }}
			/>
			{errors.minXp && <span style={{ color: "red" }}>{errors.minXp}</span>}

			<Title level={5}>Maximum XP</Title>
			<Input
				name='userExperiencePointsMax'
				type='number'
				placeholder='Maximal XP angeben'
				value={currentTaskFlow.userExperiencePointsMax}
				onChange={handleInputChange}
				style={{ marginBottom: "10px" }}
			/>
			{errors.maxXp && <span style={{ color: "red" }}>{errors.maxXp}</span>}

			<Title level={5}>
				<span style={{ color: "red" }}>*</span> Zu verdienende XP
			</Title>
			<Input
				name='xp'
				type='number'
				placeholder='XP angeben'
				value={currentTaskFlow.xp}
				onChange={handleInputChange}
				style={{ marginBottom: "10px" }}
			/>
			{errors.xp && <span style={{ color: "red" }}>{errors.xp}</span>}

			<CategorySelectionForTaskflow
				selectedCategories={selectedCategories}
				setSelectedCategories={(categories) => {
					setSelectedCategories(categories);
					setCurrentTaskFlow({ ...currentTaskFlow, categories });
				}}
				categories={categories}
			/>
			{errors.categories && <span style={{ color: "red" }}>{errors.categories}</span>}
			<Title level={5}>Matching Parameter (Tags)</Title>
			<Row gutter={12} align="middle">
				<Col xs={16}>
					<TagInput
						tags={currentTaskFlow.matchingParameters ?? []}
						setTags={setMatchingParameter}
					/>
				</Col>
				<Col xs={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
					<Checkbox
						checked={currentTaskFlow.showMultipleResults}
						onChange={(e) =>
							setCurrentTaskFlow({
								...currentTaskFlow,
								showMultipleResults: e.target.checked,
							})
						}
					>
						Einzelnes Ergebnis für jeden Parameter
					</Checkbox>
				</Col>
			</Row>

			{/* Render Tasks */}
			<Title level={5} style={{ marginTop: "20px" }}>
				Tasks
			</Title>
			{currentTaskFlow.tasks.length > 0 ? (
				currentTaskFlow.tasks.map((task, index) => (
					<Card key={index} style={{ marginBottom: "10px" }}>
						<Row align='middle'>
							<Col span={18}>
								<strong>{task.title}</strong> - {task.taskType}
							</Col>
							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									type='link'
									onClick={() => moveTaskUp(index)}
									disabled={index === 0} // Disablen wenn es die erste Task ist
								>
									<ArrowUpOutlined />
								</Button>
								<Button
									type='link'
									onClick={() => moveTaskDown(index)}
									disabled={index === currentTaskFlow.tasks.length - 1} // Disablen wenn es die letzte Task ist
								>
									<ArrowDownOutlined />
								</Button>
								<Button type='link' onClick={() => handleEditTask(index)}>
									Bearbeiten
								</Button>
								<Button
									type='link'
									danger
									onClick={() =>
										setCurrentTaskFlow((prev) => ({
											...prev,
											tasks: prev.tasks.filter((_, i) => i !== index),
										}))
									}
								>
									Entfernen
								</Button>
							</Col>
						</Row>
					</Card>
				))
			) : (
				<p>Dem Flow sind noch keine Tasks zugewiesen.</p>
			)}

			<Button
				type='primary'
				icon={<PlusOutlined />}
				onClick={onShowTaskModal}
				block
				style={{ marginTop: "20px" }}
			>
				Task hinzufügen
			</Button>

			<Title level={4}>TaskFlow Ergebnisse hinzufügen</Title>
			{results.map((result, index) => (
				<div
					key={index}
					style={{
						marginBottom: "20px",
						padding: "10px",
						border: "1px solid #f0f0f0",
						borderRadius: "4px",
					}}
				>
					<Row gutter={12}>
						<Col xs={24} md={12}>
							<Title level={5}>Ergebnis Name</Title>
							<Input
								placeholder='Ergebnis Name'
								value={result.name}
								onChange={(e) => handleResultChange(index, "name", e.target.value)}
							/>
						</Col>
						<Col xs={24} md={12}>
							<Title level={5}>Affirmations</Title>
							<Input
								placeholder='Affirmation hinzufügen'
								value={result.affirmations}
								onChange={(e) =>
									handleResultChange(index, "affirmations", e.target.value)
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={24}>
							<Title level={5}>Beschreibung</Title>
							<Input
								placeholder='Beschreibung'
								value={result.description}
								onChange={(e) =>
									handleResultChange(index, "description", e.target.value)
								}
							/>
						</Col>
					</Row>

					<Row gutter={12}>
						<Col xs={24} md={12}>
							<Title level={5}>zu verdienende Tags</Title>
							<Select
								mode='multiple'
								placeholder='Select Tag'
								style={{ width: "100%", marginBottom: "10px" }}
								value={result.tags}
								onChange={(value) => handleResultChange(index, "tags", value)}
							>
								{tagOptions.map((tag) => (
									<Option key={tag.id} value={tag.title}>
										{tag.title}
									</Option>
								))}
							</Select>
						</Col>
						<Col xs={24} md={12}>
							<CategorySelectionForTaskflow
								categories={categories}
								selectedCategories={result.categories || []} // Verwende ein Array statt eines einzelnen Werts
								setSelectedCategories={(categories) => {
									handleResultChange(index, "categories", categories); // Speichere alle Kategorien
								}}
								onlyToEarn
							/>
						</Col>
					</Row>

					<Title level={5}>Matching Parameter</Title>
					<Row gutter={12}>
						{currentTaskFlow.matchingParameters?.map((param, i) => (
							<Col key={i} span={8}>
								<Input
									placeholder={param}
									addonBefore={param}
									value={result.matchingParameters[param] || ""}
									onChange={(e) =>
										handleMatchingParameterChange(index, param, e.target.value)
									}
								/>
							</Col>
						))}
					</Row>

					<Button
						type='danger'
						icon={<DeleteOutlined />}
						onClick={() => removeResult(index)}
						style={{ marginTop: "10px" }}
					>
						Ergebnis entfernen
					</Button>
				</div>
			))}

			<Button
				type='dashed'
				icon={<PlusOutlined />}
				onClick={addResult}
				block
				style={{ marginTop: "20px" }}
			>
				Ergebnis hinzufügen
			</Button>

			<Row justify='space-between'>
				{editMode && (
					<Col>
						<Button
							type='secondary'
							onClick={() => {
								clearCurrentTaskFlow();
							}}
							block
							style={{ marginTop: "20px" }}
						>
							Abbrechen
						</Button>
					</Col>
				)}
				<Col xs={editMode ? 12 : 24}>
					<Button
						type='primary'
						onClick={handleSave}
						disabled={!currentTaskFlow.title || !currentTaskFlow.tasks?.length}
						block
						style={{ marginTop: "20px" }}
					>
						{editMode ? "TaskFlow aktualisieren" : "TaskFlow speichern"}
					</Button>
				</Col>
			</Row>
		</div>
	);
};

export default TaskFlowForm;

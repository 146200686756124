import fetch from "../interceptor/fetchInterceptor";

export function addContent(postData) {
  return fetch({
    url: "/content",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: postData,
    timeout: 5 * 60 * 1000, // 5 Minuten Timeout
  });
}

export function uploadFile(postData) {
  return fetch({
    url: "/content/upload",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: postData,
    timeout: 5 * 60 * 1000, // 5 Minuten Timeout
  });
}

export function getContent() {
  return fetch({
    url: "/content",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

export function updateContent(id, postData) {
  return fetch({
    url: `/content/${id}`,
    method: "put",
    headers: {
      "public-request": "true",
	  "Content-Type": "multipart/form-data",
    },
    data: postData,
    timeout: 5 * 60 * 1000, // 5 Minuten Timeout
  });
}

export function deleteContent(id) {
  return fetch({
    url: `/content/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
}

import React, { useEffect, useState } from "react";
import { List, Button, Row, Col, Modal, Table, Input } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const TaskList = ({ taskflows, onEdit, onDelete, categories }) => {
	// Funktion zur Bestätigung des Löschvorgangs
	const showDeleteConfirm = (taskflowId) => {
		confirm({
			title: "Möchten Sie diesen TaskFlow wirklich löschen?",
			content: "Dieser Vorgang kann nicht rückgängig gemacht werden.",
			okText: "Ja",
			okType: "danger",
			cancelText: "Nein",
			onOk() {
				onDelete(taskflowId); // Aufruf der Löschfunktion
			},
		});
	};

	const [searchText, setSearchText] = useState("");
	const [filteredTaskFlows, setFilteredTaskFlows] = useState(taskflows);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchText(value);
		const filteredData = taskflows.filter(
			(taskflow) =>
				taskflow.title.toLowerCase().includes(value) ||
				taskflow.taskFlowType.toLowerCase().includes(value) ||
				taskflow.description.toLowerCase().includes(value)
			// taskflow.categoryRequirements.some((category) =>
			// 	category.category.title.toLowerCase().includes(value)
			// )
		);
		setFilteredTaskFlows(filteredData);
		setCurrentPage(1);
	};

	useEffect(() => {
		setFilteredTaskFlows(taskflows);
	}, [taskflows]);

	const columns = [
		{
			title: "Titel",
			dataIndex: "title",
			key: "title",
			sorter: (a, b) => a.title.localeCompare(b.title),
			width: 300, // Breiter für den Titel
		},
		{
			title: "Kategorien",
			dataIndex: "categoryRequirements",
			key: "categoryRequirements",
			render: (categoryRequirements) => (
				<>
					{categoryRequirements && categoryRequirements.length > 0 && (
						<List
							size='small'
							dataSource={categoryRequirements}
							renderItem={(item) => (
								<List.Item>
									{categories.find((cat) => cat.id === item.categoryId).title}{" "}
									(Punkte {item.minLevel} - {item.maxLevel})
								</List.Item>
							)}
						/>
					)}
				</>
			),
			sorter: (a, b) => a.taskFlowType.localeCompare(b.taskFlowType),
		},
		{
			title: "zu verdienende Kategorie Punkte",
			dataIndex: "categoryParametersToEarn",
			key: "categoryParametersToEarn",
			render: (categoryParametersToEarn) => (
				<>
					{categoryParametersToEarn && categoryParametersToEarn.length > 0 && (
						<List
							size='small'
							dataSource={categoryParametersToEarn}
							renderItem={(item) => <List.Item>{item.valueToEarn}</List.Item>}
						/>
					)}
				</>
			),
			sorter: (a, b) => a.taskFlowType.localeCompare(b.taskFlowType),
		},
		{
			title: "Zu verdienende XP",
			dataIndex: "userExperiencePointsToEarn",
			key: "userExperiencePointsToEarn",
			sorter: (a, b) =>
				a.userExperiencePointsToEarn.localeCompare(b.userExperiencePointsToEarn),
		},

		{
			title: "Aktionen",
			key: "actions",
			width: 150,
			render: (_, taskFlow) => (
				<>
					<Button type='primary' onClick={() => onEdit(taskFlow)}>
						Bearbeiten
					</Button>
					<Button
						danger
						type='primary'
						style={{ marginLeft: "10px" }}
						onClick={() => showDeleteConfirm(taskFlow.id)}
					>
						<DeleteFilled style={{width: 12}} />
					</Button>
				</>
			),
		},
	];

	return (
		<>
			<Row justify='space-between' style={{ marginBottom: "10px" }}>
				<Col xs={8}>
					<Input
						placeholder='Suche nach Titel oder Typ'
						value={searchText}
						onChange={handleSearch}
						prefix={<SearchOutlined />}
					/>
				</Col>
			</Row>
			<Table
				columns={columns}
				dataSource={filteredTaskFlows}
				pagination={{
					current: currentPage,
					pageSize: pageSize,
					onChange: (page, pageSize) => {
						setCurrentPage(page);
						setPageSize(pageSize);
					},
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
					locale: {
						items_per_page: "/ Seite",
					},
					showTotal: (total, range) => `${range[0]}-${range[1]} von ${total} Einträgen`,
				}}
				rowKey={(content) => content.id}
				scroll={{ x: 1000 }}
			/>
		</>
	);
};

export default TaskList;

import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Typography, message, Upload, Select, Card } from "antd";
import { addContent, deleteContent, getContent, updateContent } from "../services/ContentService";
import { InboxOutlined } from "@ant-design/icons";
import CategorySelectionWithRange from "../components/Rules/CategorySelectionWithRange";
import { getCategories } from "../services/CategoryService";
import ContentList from "../components/Content/ContentList";
import { getMe } from "../services/AuthService";
import { ClipLoader } from "react-spinners";
import { getTags } from "../services/TagsService";

const { Title } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const contentTypes = ["IMAGE", "VIDEO", "AUDIO"];

const ContentEditor = () => {
	const [file, setFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const [content, setContent] = useState({ type: "VIDEO", tags: [] });
	const [allContent, setAllContent] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [isEditMode, setIsEditMode] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [tags, setTags] = useState([]);

	const fetchTags = async () => {
		const res = await getTags();
		setTags(res);
	};

	// Handle file upload
	const handleFileUpload = (file) => {
		const fileExtension = file.name.split(".").pop().toLowerCase();
		let type = "";

		if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
			type = "IMAGE";
		} else if (["mp4", "mov"].includes(fileExtension)) {
			type = "VIDEO";
		} else if (["mp3"].includes(fileExtension)) {
			type = "AUDIO";
		}

		// Set the type based on file extension
		setContent((prevState) => ({
			...prevState,
			type: type,
		}));

		const preview = URL.createObjectURL(file);
		setPreviewUrl(preview);
		setFile(file);
		return false; // Prevent auto-upload
	};

	const handleInput = (e) => {
		const { name, value } = e.target;

		setContent((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleTypeSelect = (selectedValue) => {
		setContent({
			...content,
			type: selectedValue,
		});
	};

	const handleTagSelect = (selectedValues) => {
		setContent({
			...content,
			tags: selectedValues,
		});
	};

	const handleSave = async () => {
		const formData = new FormData();
		formData.append("title", content.title);
		formData.append("description", content.description ?? "");
		formData.append("type", content.type);
		formData.append("userExperiencePointsMin", content.userExperiencePointsMin);
		formData.append("userExperiencePointsMax", content.userExperiencePointsMax);
		formData.append("createdById", user.id);
		formData.append("tags", content.tags ? JSON.stringify(content.tags) : "[]");
		formData.append("file", file);
		if (content.categories) {
			formData.append("categories", JSON.stringify(content.categories));
		}
		try {
			setLoading(true);
			if (isEditMode) {
				await updateContent(content.id, formData);
				message.success("Content wurde erfolgreich geupdated!");
			} else {
				await addContent(formData);
				setContent({ type: "" });
				setPreviewUrl(null);
				message.success("Content wurde erfolgreich hochgeladen!");
			}

			setContent({ type: "", tags: [] });
			setFile(null);
			setSelectedCategories([]);
			setIsEditMode(false);

			setLoading(false);
			fetchContent();
		} catch (error) {
			setLoading(false);
			message.error("Fehler beim hochladen des Contents.");
		}
	};

	const uploadProps = {
		name: "file",
		multiple: false,
		beforeUpload: handleFileUpload,
		onRemove: () => {
			setFile(null);
		},
		fileList: file ? [file] : [],
		accept: ".jpg,.jpeg,.png,.gif,.mp4,.mp3,.mov",
	};

	const handleEdit = async (content) => {
		setPreviewUrl(content.videoURL ?? content.imageURL ?? content.audioURL);
		setContent({
			...content,
			id: content.id,
			tags: content.tags?.length !== 0 ? content.tags : [],
			categories: content.categoryRequirements.map((cat) => ({
				id: cat.category.id,
				title: cat.category.title,
				min: cat.minLevel,
				max: cat.maxLevel,
			})),
		});
		setSelectedCategories(
			content.categoryRequirements.map((cat) => ({
				id: cat.category.id,
				title: cat.category.title,
				min: cat.minLevel,
				max: cat.maxLevel,
			}))
		);

		setIsEditMode(true);
	};

	const handleDelete = async (id) => {
		await deleteContent(id);
		message.success("Content erfolgreich gelöscht!");
		fetchContent();
	};

	const fetchCategories = async () => {
		const res = await getCategories();
		setCategories(res);
	};

	const fetchContent = async () => {
		const res = await getContent();
		setAllContent(res);
	};

	const fetchUser = async () => {
		const res = await getMe();
		setUser(res);
	};

	useEffect(() => {
		fetchCategories();
		fetchContent();
		fetchUser();
		fetchTags();
	}, []);

	return (
		<div className='layout-content'>
			<Row justify='center'>
				<Col xs={24}>
					<Title level={5} style={{ marginTop: "10px" }}>
						<span style={{ color: "red" }}>*</span> Datei auswählen
					</Title>
					<Row gutter={12}>
						<Col xs={content.type === "VIDEO" ? 16 : 24}>
							<Dragger
								{...uploadProps}
								style={{
									padding: "10px",
									width: "100%",
									borderRadius: "8px",
									backgroundColor: "#fafafa",
									border: "1px dashed #d9d9d9",
								}}
							>
								<p className='ant-upload-drag-icon'>
									<InboxOutlined style={{ color: "#1890ff", fontSize: "32px" }} />
								</p>
								<p className='ant-upload-text'>
									Klicke oder ziehe das Bild in diesen Bereich zum hochladen
								</p>
								{previewUrl && content?.type === "IMAGE" && (
									<div style={{ marginTop: 16 }}>
										<img
											src={previewUrl}
											alt='preview'
											style={{ maxWidth: "100%", maxHeight: 100 }}
										/>
									</div>
								)}

								{previewUrl && content?.type === "AUDIO" && (
									<div style={{ marginTop: 16 }}>
										<audio controls style={{ maxWidth: "100%" }}>
											<source src={previewUrl} type={file.type} />
											Your browser does not support the audio element.
										</audio>
									</div>
								)}
							</Dragger>
						</Col>

						{previewUrl && content?.type === "VIDEO" && (
							<Col xs={8}>
								<video controls style={{ width: "100%", maxHeight: "240px" }}>
									<source src={previewUrl} />
									Your browser does not support the video tag.
								</video>
							</Col>
						)}
					</Row>

					{/* Titel und Content-Typ in einer Zeile */}
					<Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
						<Col xs={24} md={12}>
							<Title level={5}>
								<span style={{ color: "red" }}>*</span> Titel
							</Title>
							<Input
								name='title'
								placeholder='Titel angeben'
								value={content.title}
								onChange={handleInput}
								style={{ marginBottom: "10px" }}
							/>
						</Col>

						<Col xs={24} md={12}>
							<Title level={5}>
								<span style={{ color: "red" }}>*</span> Content-Typ
							</Title>
							<Select
								placeholder='Content-Typ auswählen'
								value={content.type}
								onChange={handleTypeSelect}
								style={{ width: "100%", marginBottom: "10px" }}
							>
								{contentTypes.map((type) => (
									<Option key={type} value={type}>
										{type}
									</Option>
								))}
							</Select>
						</Col>
					</Row>

					<Title level={5} style={{ marginTop: "10px" }}>
						Beschreibung
					</Title>
					<Input.TextArea
						name='description'
						placeholder='Beschreibung eingeben'
						value={content.description}
						onChange={handleInput}
						style={{ marginBottom: "10px" }}
					/>

					{/* Minimum und Maximum XP in einer Zeile */}
					<Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
						<Col xs={24} md={12}>
							<Title level={5}>Mindestens erforderliche XP</Title>
							<Input
								name='userExperiencePointsMin'
								type='number'
								placeholder='Mindest XP angeben'
								value={content.userExperiencePointsMin}
								onChange={handleInput}
								style={{ marginBottom: "10px" }}
							/>
						</Col>

						<Col xs={24} md={12}>
							<Title level={5}>Maximum XP</Title>
							<Input
								name='userExperiencePointsMax'
								type='number'
								placeholder='Maximale XP angeben'
								value={content.userExperiencePointsMax}
								onChange={handleInput}
								style={{ marginBottom: "10px" }}
							/>
						</Col>
					</Row>

					<Title level={5}>Tags auswählen (optional)</Title>
					<Select
						mode='multiple'
						placeholder='Tags auswählen'
						value={content.tags}
						onChange={handleTagSelect}
						style={{ width: "100%", marginBottom: "10px" }}
					>
						{tags.map((tag) => (
							<Option key={tag.id} value={tag.title}>
								{tag.title}
							</Option>
						))}
					</Select>

					<CategorySelectionWithRange
						selectedCategories={selectedCategories}
						setSelectedCategories={(categories) => {
							setSelectedCategories(categories);
							setContent({ ...content, categories });
						}}
						categories={categories}
					/>

					<Button
						type='primary'
						onClick={handleSave}
						disabled={!content.title || !content.type || (!file && !isEditMode)}
						block
						style={{ marginTop: "20px" }}
					>
						{loading ? <ClipLoader /> : "Content speichern"}
					</Button>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<ContentList
							allContent={allContent}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ContentEditor;

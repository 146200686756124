import fetch from "../interceptor/fetchInterceptor";

export function addTaskflow(postData) {
	return fetch({
		url: "/task-flows",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function getTaskflows() {
	return fetch({
		url: "/task-flows",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function getTaskflowById(id) {
	return fetch({
		url: `/task-flows/${id}`,
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function updateTaskflow(postData) {
	const id = postData.id;
	
	return fetch({
		url: `/task-flows/${id}`,
		method: "put",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function deleteTaskflow(id) {
	return fetch({
		url: `/task-flows/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}
import React from "react";
import { Row, Col, Select, Input, Typography } from "antd";

const { Option } = Select;
const { Title } = Typography;

const CategorySelectionWithRange = ({ selectedCategories, setSelectedCategories, categories }) => {
	const handleCategoryChange = (value) => {
		const updatedCategories = value
			.map((categoryId) => {
				const existingCategory = selectedCategories.find((cat) => cat.id === categoryId);
				const categoryObj = categories.find((cat) => cat.id === categoryId);
				return (
					existingCategory || {
						id: categoryId,
						title: categoryObj.title,
						min: 0,
						max: 0,
					}
				); // default min and max values
			})
			.filter(Boolean);
		setSelectedCategories(updatedCategories);
	};

	const handleMinMaxChange = (categoryId, type, value) => {
		const updatedCategories = selectedCategories.map((cat) =>
			cat.id === categoryId ? { ...cat, [type]: value } : cat
		);
		setSelectedCategories(updatedCategories);
	};

	return (
		<div>
			<Title level={5}>
				Kategorien
			</Title>
			<Select
				mode='multiple'
				placeholder='Kategorien auswählen'
				value={selectedCategories.map((cat) => cat.id)}
				onChange={handleCategoryChange}
				style={{ width: "100%", marginBottom: "10px" }}
			>
				{categories.map((category) => (
					<Option key={category.id} value={category.id}>
						{category.title}
					</Option>
				))}
			</Select>

			{selectedCategories.map((category) => (
				<Row key={category.id} gutter={16} style={{ marginBottom: "10px" }}>
					<Col span={8}>
						<span>{category.title}</span>
					</Col>
					<Col span={8}>
						<Input
							type='number'
							addonBefore="Min Punkte"
							placeholder='Min Punkte'
							value={category.min}
							onChange={(e) =>
								handleMinMaxChange(category.id, "min", Number(e.target.value))
							}
						/>
					</Col>
					<Col span={8}>
						<Input
							type='number'
							addonBefore="Max Punkte"
							placeholder='Max Punkte'
							value={category.max}
							onChange={(e) =>
								handleMinMaxChange(category.id, "max", Number(e.target.value))
							}
						/>
					</Col>
				</Row>
			))}
		</div>
	);
};

export default CategorySelectionWithRange;

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Select } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";

function LineChart() {
	const { Title } = Typography;
	const { Option } = Select;
	const [selectedYear, setSelectedYear] = useState("2023");

	const handleYearChange = (value) => {
		setSelectedYear(value);
	};

	return (
		<>
			<div className='linechart'>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Title level={5}>Nutzerzuwachs</Title>
				</div>
				<Select
					defaultValue={selectedYear}
					onChange={handleYearChange}
					style={{ width: 120 }}
				>
					<Option value='2023'>2023</Option>
					<Option value='2022'>2022</Option>
					<Option value='2021'>2021</Option>
				</Select>
			</div>

			<ReactApexChart
				className='full-width'
				options={lineChart.options}
				series={lineChart.series}
				type='area'
				height={350}
				width={"100%"}
			/>
			<div className='sales'>
				<ul>
					<li>{<MinusOutlined />} Paid Accounts</li>
					<li>{<MinusOutlined />} Free Accounts</li>
				</ul>
			</div>
		</>
	);
}

export default LineChart;

import { Button, Card, Col, Input, message, Row, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { DIARY_QUESTION_TYPES } from "../config/constants/diaryQuestionType";
import DiaryQuestionList from "../components/DiaryQuestions/DiaryQuestionList";
import {
	addDiaryQuestion,
	deleteDiaryQuestion,
	fetchDiaryQuestion,
	updateDiaryQuestion,
} from "../services/DiaryQuestionService";

const { Title } = Typography;
const { Option } = Select;

const DiaryQuestions = () => {
	const [textInput, setTextInput] = useState("");
	const [questionType, setQuestionType] = useState("");
	const [minUserXP, setMinUserXP] = useState("");
	const [maxUserXP, setMaxUserXP] = useState("");
	const [isEditMode, setIsEditMode] = useState(false);
	const [allDiaryQuestions, setAllDiaryQuestions] = useState([]);
	const [questionId, setQuestionId] = useState("");

	const isValid = textInput !== "" && questionType !== "";

	const handleEdit = (level) => {
		console.log("DAs", level);
		setQuestionId(level.id);
		setTextInput(level.question);
		setQuestionType(level.type);
		setIsEditMode(true);
		setMinUserXP(level.minUserXP);
		setMaxUserXP(level.maxUserXP);
	};

	const handleDelete = async (id) => {
		await deleteDiaryQuestion(id);
		message.success("Frage entfernt");
		fetchAllDiaryQuestions();
	};

	const handleSave = async () => {
		if (!isValid) {
			message.error("Frage und Fragetyp angegeben werden.");
			return;
		}

		if (isEditMode) {
			const postData = {
				question: textInput,
				type: questionType,
				minUserXP: minUserXP ? parseInt(minUserXP) : null,
				maxUserXP: maxUserXP ? parseInt(maxUserXP) : null,
			};
			await updateDiaryQuestion(questionId, postData);
			message.success(`Frage erfolgreich ${isEditMode ? "aktualisiert" : "hinzugefügt"}!`);
		} else {
			const postData = {
				question: textInput,
				type: questionType,
        minUserXP: minUserXP ? parseInt(minUserXP) : null,
        maxUserXP: maxUserXP ? parseInt(maxUserXP) : null,
			};

			await addDiaryQuestion(postData);
			message.success(`Frage erfolgreich ${isEditMode ? "aktualisiert" : "hinzugefügt"}!`);
		}

		setTextInput("");
		setQuestionType("");
		setMinUserXP("");
		setMaxUserXP("");
		setQuestionId("");
		setIsEditMode(false);
		fetchAllDiaryQuestions();
	};

	const fetchAllDiaryQuestions = async () => {
		const res = await fetchDiaryQuestion();
		setAllDiaryQuestions(res);
	};

	useEffect(() => {
		fetchAllDiaryQuestions();
	}, []);

	return (
		<div className='layout-content'>
			<Row gutter={[24, 0]}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox '>
						<div>
							<Title level={3}>Fragen zum Tagebuch</Title>
							<Row align='middle' gutter={[24, 0]}>
								<Col xs={24} sm={12} lg={14}>
									<Title level={5}>
										<span style={{ color: "red" }}>* </span>Frage
									</Title>
									<Input
										type='text'
										placeholder='Bitte geben Sie Text für die Frage ein'
										value={textInput}
										onChange={(e) => setTextInput(e.target.value)}
									/>
								</Col>
								<Col xs={24} sm={12} lg={8}>
									<Title level={5}>
										<span style={{ color: "red" }}>* </span>Fragetyp
									</Title>
									<Select
										placeholder='Wählen Sie den Fragetyp aus'
										style={{ width: "100%" }}
										value={questionType}
										onChange={(value) => setQuestionType(value)}
									>
										{DIARY_QUESTION_TYPES.map((type) => (
											<Option key={type.key} value={type.key}>
												{type.name}
											</Option>
										))}
									</Select>
								</Col>
							</Row>
							<Row gutter={12} style={{ marginTop: 10 }}>
								{/* add number input to select minimum and maximum User XP */}
								<Col xs={6}>
									<Input
										type='number'
										placeholder='Min User XP'
										value={minUserXP}
										onChange={(e) => {
											setMinUserXP(e.target.value);
										}}
									/>
								</Col>
								<Col xs={6}>
									<Input
										type='number'
										placeholder='Max User XP'
										value={maxUserXP}
										onChange={(e) => {
											setMaxUserXP(e.target.value);
										}}
									/>
								</Col>
							</Row>
						</div>

						<Button
							type='primary'
							onClick={handleSave}
							disabled={!isValid}
							block
							style={{ marginTop: "20px" }}
						>
							{isEditMode ? "Aktualisieren" : "Hinzufügen"}
						</Button>
					</Card>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<DiaryQuestionList
							allDiaryQuestions={allDiaryQuestions}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default DiaryQuestions;

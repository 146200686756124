import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Input,
	Select,
	Button,
	Modal,
	Upload,
	Card,
	message,
	Radio,
} from "antd";
import { PlusOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { uploadFile } from "../../services/ContentService";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

const TaskForm = ({
	taskFlow,
	taskTypes,
	isTaskModalVisible,
	handleTaskModalCancel,
	handleAddTask,
	handleFileUpload,
	removeFile,
	tagOptions,
	taskFlowList,
	handleEditTask,
	editMode,
	taskIndex,
	matchingParameters,
	editTask,
}) => {
	const [task, setTask] = useState(
		editTask || {
			title: "",
			description: "",
			taskType: "",
			file: "",
			answers: [],
		}
	);

	useEffect(() => {
		if (editTask) {
			const updatedTask = {
				id: editTask.id,
				title: editTask.title || "",
				description: editTask.description || "",
				taskType: editTask.taskType || "",
				file: editTask.imageURL || editTask.videoURL || "",
				type: editTask.type || "",
				confirmationType: editTask.confirmationType || null,
				confirmationAmount: editTask.confirmationAmount || null,
				nextTaskId: editTask.nextTaskId || null,
				answers:
					editTask.answers?.map((answer) => ({
						text: answer.text || answer.answerText || null,
						tags: answer.tagsToEarn || [],
						affirmation: answer.affirmationsToEarn?.join(", ") || null,
						nextTaskId: answer.nextTaskId || null,
						nextTaskflowId: answer.nextTaskflowId || answer.nextTaskFlowId || null,
						image: answer.imageURL || "",
						matchingParameters:
							answer?.matchingParameters ||
							matchingParameters?.map((param) => ({ key: param, value: "" })) ||
							[],
					})) ?? [],
			};
			setTask(updatedTask);
		}
	}, [editTask, matchingParameters]);

	const [errors, setErrors] = useState([]);

	const validateAnswers = () => {
		const newErrors = task.answers.map((answer, index) => {
			if (!answer.text || answer.text.trim() === "") {
				return { index, message: "Antworttext darf nicht leer sein" };
			}
			return null;
		}).filter((error) => error !== null);

		setErrors(newErrors);
		return newErrors.length === 0; // Valid if no errors
	};

	const handleSubmitTask = () => {
		if (!validateAnswers()) {
			message.error("Bitte korrigiere erst die bestehenden Fehler.");
			return;
		}

		handleAddTask(task);
		setTask({
			title: "",
			description: "",
			taskType: "",
			file: "",
			answers: [],
		});
		setErrors([]); // Reset errors after successful submission
	};

	const [previewImage, setPreviewImage] = useState("");
	const [previewVisible, setPreviewVisible] = useState(false);
	const [uploading, setUploading] = useState(false); // Track if upload is in progress

	const addAnswer = () => {
		setTask({
			...task,
			answers: [
				...task.answers,
				{
					text: "",
					tags: [],
					affirmation: "",
					nextTaskId: null,
					nextTaskflowId: null,
					image: "",
					matchingParameters: matchingParameters?.map((param) => ({
						key: param,
						value: "",
					})),
				},
			],
		});
	};

	const removeAnswer = (index) => {
		const newAnswers = task.answers.filter((_, idx) => idx !== index);
		setTask({ ...task, answers: newAnswers });
	};

	const updateAnswer = (index, field, value) => {
		const newAnswers = [...task.answers];
		newAnswers[index][field] = value;
		setTask({ ...task, answers: newAnswers });
	};

	const handlePreview = (file) => {
		setPreviewImage(file.url || file.thumbUrl);
		setPreviewVisible(true);
	};

	const handleCancelPreview = () => setPreviewVisible(false);

	const handleTaskFileUpload = async (file) => {
		const formData = new FormData();
		formData.append("file", file);

		setUploading(true); // Start the uploading state

		try {
			// Call the API to upload the file
			const response = await uploadFile(formData);

			// Update the task with the returned file URL
			setTask({ ...task, file: response.url });
			setUploading(false); // End the uploading state
			message.success("Datei hinzugefügt");
		} catch (error) {
			console.error("File upload failed:", error);
			setUploading(false); // End the uploading state
			message.error("File upload Fehler");
		}

		return false; // Prevent default upload behavior
	};

	const handleRemoveTaskFile = () => {
		setTask({ ...task, file: "" });
	};

	const closeModal = () => {
		setTask({
			title: "",
			description: "",
			taskType: "",
			file: "",
			answers: [],
		});
		handleTaskModalCancel();
	};

	return (
		<Modal
			title={editMode ? "Edit Task" : "Add Task"}
			visible={isTaskModalVisible}
			onCancel={closeModal}
			footer={[
				<Button key='back' onClick={closeModal}>
					Abbrechen
				</Button>,
				<Button key='submit' type='primary' onClick={handleSubmitTask}>
					{editMode ? "Task aktualisieren" : "Task hinzufügen"}
				</Button>,
			]}
			width={1400}
			bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
		>
			<Row gutter={12}>
				<Col span={12}>
					<TextArea
						placeholder='Text'
						name='title'
						value={task.title}
						onChange={(e) => setTask({ ...task, title: e.target.value })}
					/>
				</Col>
				<Col span={12}>
					<TextArea
						placeholder='Beschreibung'
						name='description'
						value={task.description}
						onChange={(e) => setTask({ ...task, description: e.target.value })}
					/>
				</Col>
			</Row>
			<Row gutter={12} style={{ marginTop: 12, marginBottom: 12 }}>
				<Col span={12}>
					<Select
						placeholder='Task Typ'
						style={{ width: "100%" }}
						value={task.taskType}
						onChange={(value) => setTask({ ...task, taskType: value })}
					>
						{taskTypes.map((type) => (
							<Option key={type} value={type}>
								{type}
							</Option>
						))}
					</Select>
				</Col>
				<Col span={6}>
					<Upload
						beforeUpload={handleTaskFileUpload}
						fileList={task.file ? [{ uid: "-1", url: task.file }] : []}
						onRemove={handleRemoveTaskFile}
						accept='image/*,audio/*,video/*'
					>
						<Button icon={<UploadOutlined />}>
							{uploading ? "uploading..." : "Datei hinzufügen"}
						</Button>
					</Upload>
					{/* preview of file */}
					{task.file && task.taskType === "IMAGE" && (
						<img
							src={task.file}
							alt='task'
							style={{ width: "auto", height: "100px" }}
						/>
					)}
					{task.file && task.taskType === "VIDEO" && (
						<video controls style={{ width: "auto", height: "100px" }} key={task.file}>
							<source src={task.file} type='video/mp4' />
							Your browser does not support the video tag.
						</video>
					)}
				</Col>
				<Col span={6}>
					<Select
						placeholder='Nächste Task wählen (optional)'
						value={taskFlow.tasks[parseInt(task.nextTaskId)]?.title}
						onChange={(value) => setTask({ ...task, nextTaskId: value?.toString() })}
						onDeselect={(value) => setTask({ ...task, nextTaskId: null })}
						style={{ width: "100%", marginBottom: "10px" }}
						allowClear
					>
						{taskFlow.tasks.map((q, idx) => (
							<Option key={idx} value={idx}>
								{q.title}
							</Option>
						))}
					</Select>
				</Col>
			</Row>
			{["MULTIPLE_CHOICE_QUESTION", "SINGLE_CHOICE_QUESTION", "INTERMEDIATE_SCREEN"].includes(
				task.taskType
			) && (
				<Col>
					{task.answers.map((answer, index) => (
						<Card
							key={index}
							style={{ marginBottom: 20, padding: 10, border: "1px solid #f0f0f0" }}
							bodyStyle={{ padding: 10 }}
						>
							<Row gutter={[16, 16]} align='end' justify='center'>
								<Col span={14}>
									<Input
										placeholder='Antworttext'
										value={answer.text}
										onChange={(e) =>
											updateAnswer(index, "text", e.target.value)
										}
										style={{ marginBottom: "10px" }}
									/>
									{errors.find((error) => error.index === index) && (
										<div style={{ color: "red" }}>
											{errors.find((error) => error.index === index)?.message}
										</div>
									)}

								</Col>
								<Col span={5}>
									<Select
										mode='multiple'
										placeholder='Select Tag'
										value={answer.tags}
										onChange={(value) => updateAnswer(index, "tags", value)}
										style={{ width: "100%", marginBottom: "10px" }}
									>
										{tagOptions.map((tag) => (
											<Option key={tag.id} value={tag.title}>
												{tag.title}
											</Option>
										))}
									</Select>
								</Col>
								<Col span={5}>
									<Input
										placeholder='Affirmation'
										value={answer.affirmation}
										onChange={(e) =>
											updateAnswer(index, "affirmation", e.target.value)
										}
										style={{ marginBottom: "10px" }}
									/>
								</Col>
								<Col span={4}>
									<Upload
										beforeUpload={(file) => {
											const reader = new FileReader();
											reader.onload = (e) => {
												updateAnswer(index, "image", e.target.result);
											};
											reader.readAsDataURL(file);
											return false;
										}}
										accept='image/*'
										fileList={
											answer.image ? [{ uid: "-1", url: answer.image }] : []
										}
										onPreview={handlePreview}
										onRemove={() => updateAnswer(index, "image", null)}
									>
										<Button icon={<UploadOutlined />}>Bild hinzufügen</Button>
									</Upload>
								</Col>
								<Col span={7}>
									<Select
										placeholder='Nächste Task wählen (optional)'
										value={taskFlow.tasks[parseInt(answer.nextTaskId)]?.title}
										onChange={(value) =>
											updateAnswer(index, "nextTaskId", value?.toString())
										}
										style={{ width: "100%", marginBottom: "10px" }}
										onDeselect={(value) =>
											updateAnswer(index, "nextTaskId", null)
										}
										allowClear
									>
										{taskFlow.tasks.map((q, idx) => (
											<Option key={idx} value={idx}>
												{q.title}
											</Option>
										))}
									</Select>
								</Col>
								<Col span={7}>
									<Select
										placeholder='Nächsten Taskflow wählen (optional)'
										value={answer.nextTaskflowId}
										onChange={(value) =>
											updateAnswer(index, "nextTaskflowId", value)
										}
										style={{ width: "100%", marginBottom: "10px" }}
										onDeselect={(value) =>
											updateAnswer(index, "nextTaskflowId", null)
										}
										allowClear
									>
										{taskFlowList.map((taskflow, idx) => (
											<Option key={idx} value={taskflow.id}>
												{taskflow.title}
											</Option>
										))}
									</Select>
								</Col>
								<Col xs={24}>
									<Row gutter={12}>
										{matchingParameters?.map((param, paramIndex) => (
											<Col key={paramIndex}>
												<Input
													placeholder={param}
													addonBefore={param}
													value={
														answer.matchingParameters[paramIndex]
															?.value || ""
													}
													type='number'
													onChange={(e) => {
														const newAnswers = [...task.answers];
														newAnswers[index].matchingParameters[
															paramIndex
														] = {
															key: param,
															value: e.target.value,
														};
														setTask({ ...task, answers: newAnswers });
													}}
												/>
											</Col>
										))}
									</Row>
								</Col>
								<Col span={1}>
									<Button type='danger' onClick={() => removeAnswer(index)}>
										<DeleteOutlined style={{ width: 12 }} />
									</Button>
								</Col>
							</Row>
						</Card>
					))}
					<Col span={24}>
						<Button type='dashed' onClick={addAnswer} style={{ width: "100%" }}>
							<PlusOutlined /> Antwort hinzufügen
						</Button>
					</Col>
				</Col>
			)}
			{task.taskType === "CONFIRMATION" && (
				<Row gutter={12}>
					<Col xs={12}>
						{/* radiobutton to select confirmationtype: daily, weekly or monthly */}
						<Radio.Group
							onChange={(e) => setTask({ ...task, confirmationType: e.target.value })}
							value={task.confirmationType}
						>
							<Radio value='daily'>Täglich</Radio>
							<Radio value='weekly'>Wöchentlich</Radio>
							<Radio value='monthly'>Monatlich</Radio>
						</Radio.Group>
					</Col>
					<Col xs={12}>
						{/* number input to select amount when weekly or monthly */}
						<Input
							placeholder='Anzahl notwendiger Bestätigung'
							type='number'
							value={task.confirmationAmount}
							onChange={(e) =>
								setTask({ ...task, confirmationAmount: parseInt(e.target.value) })
							}
						/>
					</Col>
				</Row>
			)}
			<Modal visible={previewVisible} footer={null} onCancel={handleCancelPreview}>
				<img alt='preview' style={{ width: "100%" }} src={previewImage} />
			</Modal>
		</Modal>
	);
};

export default TaskForm;

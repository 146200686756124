import fetch from "../interceptor/fetchInterceptor";

export function addUserLevel(postData) {
	return fetch({
		url: "/user-level",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function getUserLevel() {
	return fetch({
		url: "/user-level",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function updateUserLevel(id, postData) {
	return fetch({
		url: `/user-level/${id}`,
		method: "patch",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function deleteUserLevel(id) {
	return fetch({
		url: `/user-level/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}

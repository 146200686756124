import fetch from "../interceptor/fetchInterceptor";

export function addAchievement(postData) {
  return fetch({
    url: "/achievements",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: postData,
  });
}

export function getAchievement() {
  return fetch({
    url: "/achievements",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

export function updateAchievement(id, postData) {
  return fetch({
    url: `/achievements/${id}`,
    method: "put",
    headers: {
      "public-request": "true",
	  "Content-Type": "multipart/form-data",
    },
    data: postData,
  });
}

export function deleteAchievement(id) {
  return fetch({
    url: `/achievements/${id}`,
    method: "delete",
    headers: {
      "public-request": "true",
    },
  });
}

export function getAchievementCategories(id) {
  return fetch({
    url: `/achievements/${id}/categories`,
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

import fetch from "../interceptor/fetchInterceptor";

export function userLogin(postData) {
  return fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function getMe() {
	const token = localStorage.getItem("token");
  
	return fetch({
	  url: "/users/me",
	  method: "get",
	  headers: {
		"Authorization": `Bearer ${token}`,
		"public-request": "true",
	  },
	});
  }
  
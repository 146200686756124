import fetch from "../interceptor/fetchInterceptor";

export function addDiaryQuestion(postData) {
  return fetch({
    url: "/diary-questions",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function fetchDiaryQuestion() {
  return fetch({
    url: "/diary-questions",
    method: "get",
    headers: {
      "public-request": "true",
    },
  });
}

export function updateDiaryQuestion(id, postData) {
  return fetch({
    url: `/diary-questions/${id}`,
    method: "patch",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}

export function deleteDiaryQuestion(id) {
	return fetch({
		url: `/diary-questions/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}
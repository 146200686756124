const lineChart = {
	series: [
		{
			name: "Free Accounts",
			data: [0, 350, 40, 300, 220, 500, 250, 400, 230, 500, 510, 560],
			offsetY: 0,
		},
		{
			name: "Paid Accounts",
			data: [0, 30, 90, 40, 140, 290, 290, 340, 200, 400, 490, 510],
			offsetY: 0,
		},
	],

	options: {
		chart: {
			width: "100%",
			height: 350,
			type: "area",
			toolbar: {
				show: false,
			},
		},

		legend: {
			show: false,
		},

		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},

		yaxis: {
			labels: {
				style: {
					fontSize: "14px",
					fontWeight: 600,
					colors: ["#8c8c8c"],
				},
			},
		},

		xaxis: {
			labels: {
				style: {
					fontSize: "14px",
					fontWeight: 600,
					colors: [
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
					],
				},
			},
			categories: [
				"Jan",
				"Feb",
				"Mär",
				"Apr",
				"Mai",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Okt",
				"Nov",
				"Dez",
			],
		},

		tooltip: {
			y: {
				formatter: function (val) {
					return val;
				},
			},
		},
	},
};

export default lineChart;

import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card, Input, Button, Typography, Select, message } from "antd";
import { getTags } from "../services/TagsService";
import {
	addCategory,
	deleteCategory,
	getCategories,
	updateCategory,
} from "../services/CategoryService";
import CategoryList from "../components/Category/CategoryList";
import { BASE_CATEGORIES } from "../config/constants/categories";
import { DeleteFilled } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const Category = () => {
	const [tags, setTags] = useState([]);
	const [categoryData, setCategoryData] = useState({
		title: "",
		description: "",
		tags: [],
		levels: [{ level: 0, minXP: 0, maxXP: 0 }],
		baseCategory: "JOY_OF_LIFE",
	});
	const [categories, setCategories] = useState([]);
	const [errors, setErrors] = useState({ title: "" });
	const [isEditMode, setIsEditMode] = useState(false);

	const fetchTags = async () => {
		const res = await getTags();
		setTags(res);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setCategoryData({
			...categoryData,
			[name]: value,
		});

		// Validation for title
		if (name === "title") {
			if (!value.trim()) {
				setErrors({ ...errors, title: "Bezeichnung ist verpflichtend" });
			} else {
				setErrors({ ...errors, title: "" });
			}
		}
	};

	const handleTagSelect = useCallback((selectedValues) => {
		setCategoryData((prevData) => ({
			...prevData,
			tags: selectedValues,
		}));
	}, []);

	const handleLevelChange = (index, key, value) => {
		const newLevels = [...categoryData.levels];
		newLevels[index][key] = value;
		setCategoryData({ ...categoryData, levels: newLevels });
	};

	const handleAddLevel = () => {
		const highestLevel =
			categoryData.levels.length > 0
				? Math.max(...categoryData.levels.map((lvl) => lvl.level))
				: 0;
		const lastMinXP =
			categoryData.levels.length > 0
				? Math.max(...categoryData.levels.map((lvl) => lvl.minXP))
				: 0;

		setCategoryData({
			...categoryData,
			levels: [
				...categoryData.levels,
				{ level: highestLevel + 1, minXP: lastMinXP + 100, maxXP: lastMinXP + 200 },
			],
		});
	};

	const handleDeleteLevel = (index) => {
		const newLevels = categoryData.levels.filter((_, i) => i !== index);
		setCategoryData({ ...categoryData, levels: newLevels });
	};

	const handleSave = async () => {
		try {
			if (isEditMode) {
				const response = await updateCategory(categoryData.id, categoryData);
				if (response) {
					message.success("Kategorie erfolgreich aktualisiert!");
				}
			} else {
				delete categoryData.id;

				const response = await addCategory(categoryData);
				if (response) {
					message.success("Kategorie erfolgreich hinzugefügt!");
				}
			}
			setCategoryData({
				id: null,
				title: "",
				description: "",
				tags: [],
				levels: [{ level: 0, minXP: 0, maxXP: 0 }],
				baseCategory: "JOY_OF_LIFE",
			});
			setIsEditMode(false);
			fetchCategories();
		} catch (error) {
			message.error("Fehler beim Speichern der Kategorie");
		}
	};

	const isTitleValid = categoryData.title.trim().length > 0;

	const handleEdit = (category) => {
		setCategoryData({
			id: category.id,
			title: category.title,
			description: category.description,
			tags: category.tags.map((tag) => tag),
			levels: category.levels || [{ level: 0, minXP: 0, maxXP: 0 }],
			baseCategory: category.baseCategory,
		});
		setIsEditMode(true);
	};

	const handleDelete = async (id) => {
		await deleteCategory(id);
		fetchCategories();
		message.success("Kategorie erfolgreich gelöscht!");
	};

	const fetchCategories = async () => {
		const response = await getCategories();
		setCategories(response);
	};

	useEffect(() => {
		fetchCategories();
		fetchTags();
	}, []);

	return (
		<div className='layout-content'>
			<Row gutter={[24, 0]}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<div className='number'>
							<Row align='middle' gutter={[24, 0]}>
								<Col xs={24}>
									<Title level={3}>Kategorie Editor (Wirkhebel)</Title>

									<Row gutter={12}>
										<Col xs={18}>
											<Title level={5}>
												<span style={{ color: "red" }}>*</span>Kategorie
												Name
											</Title>
											<Input
												placeholder='Kategorie Bezeichnung eingeben'
												name='title'
												value={categoryData.title}
												onChange={handleInputChange}
												style={{ marginBottom: "4px" }}
											/>
											{errors.title && (
												<span style={{ color: "red" }}>{errors.title}</span>
											)}
										</Col>
										<Col xs={6}>
											{/* dropwdown for baseCategory */}
											<Title level={5}>
												<span style={{ color: "red" }}>*</span>Basis
												Kategorie
											</Title>
											<Select
												placeholder='Basis Kategorie auswählen'
												style={{ width: "100%" }}
												value={categoryData.baseCategory}
												onChange={(value) =>
													setCategoryData({
														...categoryData,
														baseCategory: value,
													})
												}
											>
												{BASE_CATEGORIES.map((category) => (
													<Option key={category.key} value={category.key}>
														{category.name}
													</Option>
												))}
											</Select>
										</Col>
									</Row>

									<Title level={5} style={{ marginTop: "10px" }}>
										Kategorie Beschreibung (optional)
									</Title>
									<Input.TextArea
										placeholder='Beschreibung eingeben (optional)'
										name='description'
										value={categoryData.description}
										onChange={handleInputChange}
										style={{ marginBottom: "10px" }}
									/>

									<Title level={5}>Tags auswählen (optional)</Title>
									<Select
										mode='multiple'
										placeholder='Tags auswählen'
										value={categoryData.tags}
										onChange={handleTagSelect}
										style={{ width: "100%", marginBottom: "10px" }}
									>
										{tags.map((tag) => (
											<Option key={tag.id} value={tag.title}>
												{tag.title}
											</Option>
										))}
									</Select>

									<Title level={5}>Level</Title>
									{categoryData.levels.map((levelObj, index) => (
										<div key={index} style={{ marginBottom: "10px" }}>
											<Input
												placeholder='Level'
												addonBefore='Level'
												value={levelObj.level}
												type='number'
												onChange={(e) =>
													handleLevelChange(
														index,
														"level",
														parseInt(e.target.value) || 0
													)
												}
												style={{ width: "200px", marginRight: "10px" }}
											/>
											<Input
												placeholder='Min XP'
												addonBefore='Min XP'
												value={levelObj.minXP}
												type='number'
												onChange={(e) =>
													handleLevelChange(
														index,
														"minXP",
														parseInt(e.target.value) || 0
													)
												}
												style={{ width: "200px", marginRight: "10px" }}
											/>
											<Input
												placeholder='Max XP'
												addonBefore='Max XP'
												value={levelObj.maxXP}
												type='number'
												onChange={(e) =>
													handleLevelChange(
														index,
														"maxXP",
														parseInt(e.target.value) || 0
													)
												}
												style={{ width: "200px", marginRight: "10px" }}
											/>
											<Button
												danger
												type='primary'
												onClick={() => handleDeleteLevel(index)}
												disabled={index === 0}
											>
												<DeleteFilled
													style={{ width: 12, color: "white" }}
												/>
											</Button>
										</div>
									))}
									<Button
										type='dashed'
										onClick={handleAddLevel}
										style={{ marginBottom: "10px" }}
									>
										+ Level hinzufügen
									</Button>
								</Col>
							</Row>
						</div>
						<Button
							type='primary'
							onClick={handleSave}
							disabled={!isTitleValid}
							block
							style={{ marginTop: "20px" }}
						>
							Kategorie speichern
						</Button>
					</Card>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<CategoryList
							categories={categories}
							onEdit={handleEdit}
							onDelete={handleDelete}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default Category;
import { Layout, Row, Col } from "antd";

function Footer() {
	const { Footer: AntFooter } = Layout;

	return (
		<AntFooter style={{ background: "#fafafa" }}>
			<Row className='just'>
				<Col xs={24} md={12} lg={12}>
					<div className='copyright'>
						© {new Date().getFullYear()}{" "}
						<a
							href='https://www.mindelevate.app/'
							className='font-weight-bold'
							target='_blank'
							rel='noreferrer'
						>
							Mindelevate
						</a>
					</div>
				</Col>
			</Row>
		</AntFooter>
	);
}

export default Footer;

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Select,
  Input,
  Button,
  Typography,
  message,
} from "antd";
import { getTags } from "../services/TagsService";
import { getTaskflows } from "../services/TaskflowService";
import {
  addBusinessRule,
  deleteBusinessRule,
  getBusinessRules,
  updateBusinessRule,
} from "../services/BusinessRuleEngineService";
import BusinessRulesList from "../components/BusinessRules/BusinessRulesList.jsx";
import { getCategories } from "../services/CategoryService.js";

const { Option } = Select;
const { Title } = Typography;

const BusinessRuleEngine = () => {
  const [rules, setRules] = useState([]);
  const [ruleId, setRuleId] = useState([]);
  const [conditionId, setConditionId] = useState([]);
  const [actionId, setActionId] = useState([]);
  const [title, setTitle] = useState("");
  const [ifCondition, setIfCondition] = useState(null);
  const [operator, setOperator] = useState(null);
  const [value, setValue] = useState("");
  const [thenAction, setThenAction] = useState(null);
  const [thenInput, setThenInput] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [tagOptions, setTagOptions] = useState([]);
  const [taskflowOptions, setTaskflowOptions] = useState([]);
  const [businessRules, setBusinessRules] = useState([]);

  const [moodTimesInARow, setMoodTimesInARow] = useState("");
  const [activityValues, setActivityValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [chatMessage, setChatMessage] = useState("");

  const ifOptions = [
    "Stimmung",
    "Erreichte XP",
    "Kategoriepunkte erreicht",
    "Nicht eingeloggt",
    "Login Streak",
    "Social Battery",
    "Stimmungsschwankung",
    "Durchschnittstimmung des Tages",
    "Tagebucheintrag Word-Match",
    "Activity",
  ];
  const conditionOperators = ["=", ">", "<"];
  const moodOptions = ["BAD", "OK", "NORMAL", "GOOD", "SUPER"];
  const actionOptions = [
    "Chatnachricht senden",
    "Push-Nachricht senden",
    "Content mit Tag zeigen",
    "Spezifischen Taskflow starten",
    "Taskflow nach Kategorie starten",
    "Tagebucheintrag aus Archiv anzeigen",
  ];

  const fieldMap = {
    Stimmung: "MOOD",
    "Erreichte XP": "XP",
    "Kategoriepunkte erreicht": "CATEGORY_LEVEL",
    "Nicht eingeloggt": "NO_LOGIN",
    "Login Streak": "STREAK",
    "Social Battery": "SOCIAL_BATTERY",
    Stimmungsschwankung: "MOOD_SWINGS",
    "Durchschnittstimmung des Tages": "MOOD_EVERAGE_OF_DAY",
    "Tagebucheintrag Word-Match": "DIARY_ENTRIES_CONTAINS_WORD",
    Activity: "ACTIVITY",
  };

  const operatorMap = {
    "=": "IS",
    ">": "GREATER_THAN",
    "<": "LESS_THAN",
  };

  const typeMap = {
    "Chatnachricht senden": "SEND_MESSAGE",
    "Push-Nachricht senden": "SEND_PUSH_NOTIFICATION",
    "Content mit Tag zeigen": "SHOW_CONTENT",
    "Spezifischen Taskflow starten": "TASKFLOW",
    "Taskflow nach Kategorie starten": "TASKFLOW_BY_CATEGORY",
    "Tagebucheintrag aus Archiv anzeigen": "SHOW_DIARY_ENTRY_FROM_ARCHIVE",
  };

  const fieldReverseMap = {
    MOOD: "Stimmung",
    XP: "Erreichte XP",
    CATEGORY_LEVEL: "Kategoriepunkte erreicht",
    NO_LOGIN: "Nicht eingeloggt",
    STREAK: "Login Streak",
    SOCIAL_BATTERY: "Social Battery",
    MOOD_SWINGS: "Stimmungsschwankung",
    MOOD_EVERAGE_OF_DAY: "Durchschnittstimmung des Tages",
    DIARY_ENTRIES_CONTAINS_WORD: "Tagebucheintrag Word-Match",
    ACTIVITY: "Activity",
  };

  const operatorReverseMap = {
    IS: "=",
    GREATER_THAN: ">",
    LESS_THAN: "<",
  };

  const typeReverseMap = {
    SEND_MESSAGE: "Chatnachricht senden",
    SHOW_CONTENT: "Content mit Tag zeigen",
    TASKFLOW: "Spezifischen Taskflow starten",
    SEND_PUSH_NOTIFICATION: "Push-Nachricht senden",
    TASKFLOW_BY_CATEGORY: "Taskflow nach Kategorie starten",
    SHOW_DIARY_ENTRY_FROM_ARCHIVE: "Tagebucheintrag aus Archiv anzeigen",
  };

  const handleIfChange = (value) => {
    setIfCondition(value);
    setOperator(null);
    setValue("");
  };

  const handleAddOrUpdateRule = async () => {
    try {
      const newRule = {
        title: title,
        conditions: [
          {
            field: fieldMap[ifCondition],
            operator:
              ifCondition === "Stimmung" ||
              ifCondition === "Stimmungsschwankung" ||
              ifCondition === "Durchschnittstimmung des Tages" ||
              ifCondition === "Tagebucheintrag Word-Match" ||
              ifCondition === "Activity"
                ? "IS"
                : operatorMap[operator],
            value: ifCondition === "Stimmung" ? value : value.toString(),
            moodInRow: ifCondition === "Stimmung" ? moodTimesInARow : "",
            activityValues:
              ifCondition === "Stimmung" ||
              ifCondition === "Stimmungsschwankung" ||
              ifCondition === "Activity"
                ? activityValues
                : [],
            categoryId:
              ifCondition === "Kategoriepunkte erreicht"
                ? selectedCategory
                : "",
          },
        ],
        actions: [
          {
            type: typeMap[thenAction],
            value: [
              "Chatnachricht senden",
              "Content mit Tag zeigen",
              "Spezifischen Taskflow starten",
              "Taskflow nach Kategorie starten",
              "Tagebucheintrag aus Archiv anzeigen",
              "Push-Nachricht senden",
            ].includes(thenAction)
              ? thenInput
              : "",
            chatMessage: chatMessage,
          },
        ],
      };
      // console.log("newRule = ", newRule);
      // return true;

      if (editIndex !== null) {
        newRule.conditions[0].id = conditionId;
        newRule.actions[0].id = actionId;
        const res = await updateBusinessRule(ruleId, newRule);

        const updatedRules = [...rules];
        updatedRules[editIndex] = newRule;
        setRules(updatedRules);
        setEditIndex(null);

        if (res) {
          message.success("Regel erfolgreich aktualisiert");
          await fetchBusinessRules();
        }
      } else {
        const res = await addBusinessRule(newRule);

        if (res) {
          message.success("Regel erfolgreich hinzugefügt");
          await fetchBusinessRules();
        }
      }

      setTitle("");
      setIfCondition(null);
      setOperator(null);
      setValue("");
      setThenAction(null);
      setThenInput("");
      fetchBusinessRules();
      setSelectedCategories([]);
      setActivityValues([]);
      setChatMessage("");
    } catch (error) {
      message.error("Fehler beim Hinzufügen der Regel");
      console.error(error);
    }
  };

  const handleEditRule = (rule) => {
    console.log("rule = ", rule);

    setTitle(rule.title);
    setIfCondition(fieldReverseMap[rule.conditions[0].field]);
    setOperator(operatorReverseMap[rule.conditions[0].operator]);
    setValue(rule.conditions[0].value);
    setThenAction(typeReverseMap[rule.actions[0].type]);
    setChatMessage(rule.actions[0].chatMessage);
    if (rule.conditions[0]?.field === "CATEGORY_LEVEL") {
      setSelectedCategory(rule.conditions[0]?.categoryId ?? "");
    }

    if (rule.actions[0].type === "TASKFLOW_BY_CATEGORY") {
      try {
        const parsedCategories = JSON.parse(rule.actions[0].value);
        setThenInput(parsedCategories); // Setzt den JSON-Wert als Array für den input
        setSelectedCategories(parsedCategories);
      } catch (error) {
        console.error("JSON parsing error:", error);
      }
    } else if (rule.actions[0].type === "SHOW_DIARY_ENTRY_FROM_ARCHIVE") {
      const parsedValue = JSON.parse(rule.actions[0].value);
      setThenInput({
        mood: parsedValue.mood,
        tags: parsedValue.tags,
      });
    } else if (rule.actions[0].type === "SHOW_CONTENT") {
      const parsedValue = JSON.parse(rule.actions[0].value);
      setThenInput({
        tags: parsedValue.tags,
      });
    } else if (rule.actions[0].type === "TASKFLOW") {
      const parsedValue = rule.actions[0].value
        ? JSON.parse(rule.actions[0].value)
        : "";
      setThenInput({
        id: parsedValue?.id ?? "",
        value: parsedValue?.value ?? "",
      });
    } else {
      setThenInput(rule.actions[0].value);
    }

    setRuleId(rule.id);
    setConditionId(rule.conditions[0].id);
    setActionId(rule.actions[0].id);
    setEditIndex(1);
    setActivityValues(rule.conditions[0].activityValues);
    setMoodTimesInARow(rule.conditions[0].moodInRow);
  };

  const handleDelete = async (id) => {
    await deleteBusinessRule(id);
    fetchBusinessRules();
  };

  const fetchCategories = async () => {
    const res = await getCategories();
    setCategories(res);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const renderConditionInputs = () => {
    if (!ifCondition) return null;

    if (
      ifCondition === "Stimmung" ||
      ifCondition === "Durchschnittstimmung des Tages"
    ) {
      return (
        <>
          <>
            <Title level={4}>IS</Title>
            <Select
              placeholder="Stimmung wählen"
              onChange={(value) => setValue(value)}
              value={value}
              style={{ width: "100%", marginBottom: "10px" }}
            >
              {moodOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </>
          {ifCondition === "Stimmung" && (
            <>
              <Input
                name="moodTimesInARow"
                type="number"
                value={moodTimesInARow}
                placeholder="Stimmung x Mal in Folge"
                onChange={(e) => setMoodTimesInARow(e.target.value)}
                style={{ marginBottom: "10px" }}
              />
              <Title level={4}>Aktivität (optional)</Title>
              <Select
                mode="multiple"
                placeholder="Aktivität wählen"
                onChange={(value) => setActivityValues(value)}
                value={activityValues}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                {tagOptions.map((option) => (
                  <Option key={option.title} value={option.title}>
                    {option.title}
                  </Option>
                ))}
              </Select>
              {/* <Title level={4}>Kategorie</Title>
              <Select
                placeholder="Kategorie wählen"
                value={selectedCategory}
                onChange={handleCategoryChange}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                {categories.map((category) => (
                  <Option key={category.id} value={category.id}>
                    {category.title}
                  </Option>
                ))}
              </Select> */}
            </>
          )}
        </>
      );
    } else if (
      ifCondition === "Erreichte XP" ||
      ifCondition === "Kategoriepunkte erreicht" ||
      ifCondition === "Nicht eingeloggt" ||
      ifCondition === "Login Streak" ||
      ifCondition === "Social Battery"
    ) {
      return (
        <>
          <Title level={4}>IS</Title>
          <Row gutter={12} justify="center" align="middle">
            {ifCondition === "Kategoriepunkte erreicht" && (
              <Col xs={10}>
                <Select
                  placeholder="Kategorie wählen"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  {categories.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.title}
                    </Option>
                  ))}
                </Select>
              </Col>
            )}
            <Col xs={4}>
              <Select
                placeholder="Operator wählen"
                onChange={(value) => setOperator(value)}
                value={operator}
                style={{ width: "100%", marginBottom: "10px" }}
              >
                {conditionOperators.map((option) => (
                  <Option
                    key={option}
                    value={option}
                    disabled={
                      (ifCondition === "Erreichte XP" ||
                        ifCondition === "Kategoriepunkte erreicht" ||
                        ifCondition === "Nicht eingeloggt" ||
                        ifCondition === "Login Streak") &&
                      (option === "<" || option === ">")
                    }
                  >
                    {option}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={ifCondition === "Kategoriepunkte erreicht" ? 10 : 20}>
              <Input
                type="number"
                placeholder={
                  ifCondition === "Erreichte XP"
                    ? "Anzahl XP eingeben"
                    : ifCondition === "Kategoriepunkte erreicht"
                    ? "Kategoriepunkte eingeben"
                    : ifCondition === "Nicht eingeloggt" ||
                      ifCondition === "Login Streak"
                    ? "Anzahl der Tage (in Folge)"
                    : ifCondition === "Social Battery"
                    ? "Social Battery Wert (0 - 100)"
                    : ""
                }
                value={value}
                onChange={(e) => {
                  if (
                    ifCondition === "Social Battery" &&
                    (e.target.value < 0 || e.target.value > 100)
                  ) {
                    // set val to min 0 or max 100
                    e.target.value = e.target.value < 0 ? 0 : 100;
                  }

                  setValue(e.target.value);
                }}
                style={{ width: "100%", marginBottom: "10px" }}
                min={ifCondition === "Social Battery" ? 0 : undefined}
                max={ifCondition === "Social Battery" ? 100 : undefined}
              />
            </Col>
          </Row>
        </>
      );
    } else if (ifCondition === "Tagebucheintrag Word-Match") {
      return (
        <Input
          type="text"
          placeholder={"Zu matchendes Wort / Text eingeben"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
      );
    } else if (ifCondition === "Activity") {
      return (
        <>
          <Title level={4}>Activity</Title>
          <Select
            mode="multiple"
            placeholder="Select activity"
            onChange={(value) => setActivityValues(value)}
            value={activityValues}
            style={{ width: "100%", marginBottom: "10px" }}
          >
            {tagOptions.map((option) => (
              <Option key={option.title} value={option.title}>
                {option.title}
              </Option>
            ))}
          </Select>
        </>
      );
    }
  };

  const renderThenInput = () => {
    if (!thenAction) return null;

    if (
      thenAction === "Chatnachricht senden" ||
      thenAction === "Push-Nachricht senden"
    ) {
      return (
        <Input
          placeholder="Nachricht eingeben"
          value={thenInput}
          onChange={(e) => setThenInput(e.target.value)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
      );
    } else if (thenAction === "Content mit Tag zeigen") {
      return (
        <Select
          mode="multiple"
          placeholder="Select tag"
          onChange={(value) => {
            setThenInput({
              ...thenInput,
              tags: value,
            });
          }}
          value={thenInput?.tags}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {tagOptions.map((tag) => (
            <Option key={tag.title} value={tag.title}>
              {tag.title}
            </Option>
          ))}
        </Select>
      );
    } else if (thenAction === "Spezifischen Taskflow starten") {
      return (
        <Select
          placeholder="Select taskflow"
          onChange={(value) =>
            setThenInput({
              id: taskflowOptions.find(
                (taskflowOption) => taskflowOption.title === value
              ).id,
              value: value,
            })
          }
          value={thenInput?.value}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          {taskflowOptions.map((taskflow) => (
            <Option key={taskflow.id} value={taskflow.title}>
              {taskflow.title}
            </Option>
          ))}
        </Select>
      );
    } else if (thenAction === "Taskflow nach Kategorie starten") {
      return (
        <>
          <Select
            placeholder="Kategorie wählen"
            onChange={(value) => {
              setThenInput(
                categories.find((category) => category.id === value)
              );
              setSelectedCategories(value);
            }}
            value={
              selectedCategories.length > 0 ? selectedCategories : thenInput?.id
            }
            style={{ width: "100%", marginBottom: "10px" }}
          >
            {categories.map((category) => (
              <Option key={category.id} value={category.id}>
                {category.title}
              </Option>
            ))}
          </Select>
        </>
      );
    } else if (thenAction === "Tagebucheintrag aus Archiv anzeigen") {
      return (
        <>
          {/* add mood dropdown selector */}
          <Select
            placeholder="Stimmung des anzuzeigenden Eintrags wählen"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(value) => {
              setThenInput({
                ...thenInput,
                mood: value,
              });
            }}
            value={thenInput?.mood}
          >
            {moodOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
          {/* Tags to select */}
          <Select
            mode="multiple"
            placeholder="Tags des anzuzeigenden Eintrags wählen"
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(value) => {
              setThenInput({
                ...thenInput,
                tags: value,
              });
            }}
            value={thenInput?.tags}
          >
            {tagOptions.map((tag) => (
              <Option key={tag.title} value={tag.title}>
                {tag.title}
              </Option>
            ))}
          </Select>
        </>
      );
    }
  };

  const fetchTags = async () => {
    const res = await getTags();
    setTagOptions(res);
  };

  const fetchTaskflows = async () => {
    const res = await getTaskflows();
    setTaskflowOptions(res);
  };

  const fetchBusinessRules = async () => {
    const res = await getBusinessRules();
    setBusinessRules(res);
  };

  useEffect(() => {
    fetchTags();
    fetchTaskflows();
    fetchBusinessRules();
    fetchCategories();
  }, []);

  // When then action changes, reset then input of action
  useEffect(() => {
    // When not in edit mode, reset then input
    if (editIndex === null) {
      setThenInput(null);
      setSelectedCategories([]);
      setChatMessage(null);
    }
  }, [thenAction]);

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24}>
          <Card bordered={false} className="criclebox">
            <div>
              <Row align="middle" gutter={[24, 0]}>
                <Col xs={24}>
                  <Title level={3}>Business Rule Engine</Title>

                  <Title level={4} style={{ marginTop: "10px" }}>
                    <span style={{ color: "red" }}>*</span> Bezeichnung
                  </Title>
                  <Input
                    name="title"
                    placeholder="Bezeichnung eingeben"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  />

                  <Title level={4}>
                    <span style={{ color: "red" }}>*</span> IF
                  </Title>
                  <Select
                    placeholder="Bedingung auswählen"
                    onChange={handleIfChange}
                    value={ifCondition}
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    {ifOptions.map((option) => (
                      <Option
                        key={option}
                        value={option}
                        disabled={option === "Erreichte XP"}
                      >
                        {option}
                      </Option>
                    ))}
                  </Select>

                  {ifCondition && renderConditionInputs()}

                  <Title level={4}>
                    <span style={{ color: "red" }}>*</span> THEN
                  </Title>
                  <Select
                    placeholder="Aktion wählen"
                    onChange={(value) => setThenAction(value)}
                    value={thenAction}
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    {actionOptions.map((option) => (
                      <Option
                        key={option}
                        value={option}
                        disabled={
                          (option === "Push-Nachricht senden" &&
                            [
                              "Stimmung",
                              "Login Streak",
                              "Kategoriepunkte erreicht",
                              "Activity",
                              "Tagebucheintrag Word-Match",
                              "Stimmungsschwankung",
                            ].includes(ifCondition)) ||
                          (option !== "Push-Nachricht senden" &&
                            [
                              "Nicht eingeloggt",
                              "Durchschnittstimmung des Tages",
                              "Social Battery",
                            ].includes(ifCondition))
                        }
                      >
                        {option}
                      </Option>
                    ))}
                  </Select>

                  {thenAction && renderThenInput()}

                  {[
                    "Content mit Tag zeigen",
                    "Spezifischen Taskflow starten",
                    "Taskflow nach Kategorie starten",
                    "Tagebucheintrag aus Archiv anzeigen",
                  ].includes(thenAction) && (
                    <>
                      <Title level={4}>Text Hinweisnachricht (Chat)</Title>
                      <Input
                        placeholder="Text eingeben"
                        style={{ width: "100%", marginBottom: "10px" }}
                        value={chatMessage}
                        onChange={(e) => setChatMessage(e.target.value)}
                      />
                    </>
                  )}

                  <Button
                    type="primary"
                    block
                    onClick={handleAddOrUpdateRule}
                    disabled={
                      !title || !ifCondition || !thenAction || !thenInput
                    }
                  >
                    {editIndex !== null
                      ? "Regel aktualisieren"
                      : "Regel hinzufügen"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
        <Col xs={24}>
          <Card bordered={false} className="criclebox">
            <BusinessRulesList
              businessRules={businessRules}
              onEdit={handleEditRule}
              onDelete={handleDelete}
              operatorReverseMap={operatorReverseMap}
              fieldReverseMap={fieldReverseMap}
              typeReverseMap={typeReverseMap}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BusinessRuleEngine;

import fetch from "../interceptor/fetchInterceptor";

export function addTag(postData) {
	return fetch({
		url: "/tag",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function getTags() {
	return fetch({
		url: "/tag",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function updateTag(id, postData) {
	return fetch({
		url: `/tag/${id}`,
		method: "put",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function deleteTag(id) {
	return fetch({
		url: `/tag/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}

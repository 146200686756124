const dayChart = {
	series: [
		{
			name: "Free Accounts",
			data: [
				25, 27, 30, 28, 40, 38, 55, 50, 45, 40, 35, 60, 65, 60, 55, 50, 45, 60, 55, 70, 75,
				80, 85, 50,
			],
			offsetY: 0,
		},
		{
			name: "Paid Accounts",
			data: [
				15, 18, 22, 35, 30, 45, 42, 50, 65, 55, 50, 55, 70, 75, 70, 65, 60, 55, 50, 65, 70,
				75, 80, 45,
			],
			offsetY: 0,
		},
	],

	options: {
		chart: {
			width: "100%",
			height: 350,
			type: "area",
			toolbar: {
				show: false,
			},
		},

		legend: {
			show: false,
		},

		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},

		yaxis: {
			labels: {
				style: {
					fontSize: "14px",
					fontWeight: 600,
					colors: ["#8c8c8c"],
				},
			},
		},

		xaxis: {
			labels: {
				style: {
					fontSize: "14px",
					fontWeight: "600",
					colors: [
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
						"#8c8c8c",
					],
				},
			},
			categories: [
				"00:00",
				"01:00",
				"02:00",
				"03:00",
				"04:00",
				"05:00",
				"06:00",
				"07:00",
				"08:00",
				"09:00",
				"10:00",
				"11:00",
				"12:00",
				"13:00",
				"14:00",
				"15:00",
				"16:00",
				"17:00",
				"18:00",
				"19:00",
				"20:00",
				"21:00",
				"22:00",
				"23:00",
			],
		},

		tooltip: {
			y: {
				formatter: function (val) {
					return val;
				},
			},
		},
	},
};

export default dayChart;

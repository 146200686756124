import axios from "axios";
// import { useHistory } from "react-router-dom";
import { API_BASE_URL } from "../config/config";

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
});

service.interceptors.request.use(
	(config) => {
		const jwtToken = localStorage.getItem("token");

		if (jwtToken) {
			config.headers['Authorization'] = "Bearer " + jwtToken;
		}

		if (!jwtToken && !config.headers['Authorization']) {
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {

		if (error) {
			localStorage.removeItem('token');
		}
		return Promise.reject(error);
	}
);

export default service;

import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { DIARY_QUESTION_TYPES } from "../../config/constants/diaryQuestionType";

const { Title } = Typography;

const DiaryQuestionList = ({ allDiaryQuestions, onEdit, onDelete }) => {
  // Helper function to get the name based on type
  const getQuestionTypeName = (type) => {
    const typeEntry = DIARY_QUESTION_TYPES.find((item) => item.key === type);
    return typeEntry ? typeEntry.name : type;
  };
  return (
    <>
      <Row gutter={12}>
        <Col span={10}>
          <Title level={5}>Fragen zum Tagebuch</Title>
        </Col>
        <Col span={6}>
          <Title level={5}>Fragetyp</Title>
        </Col>
        <Col span={2}>
          <Title level={5}>Min XP</Title>
        </Col>
        <Col span={2}>
          <Title level={5}>Max XP</Title>
        </Col>
        <Col span={2}>
          <Title level={5}></Title>
        </Col>
        <Col span={2}>
          <Title level={5}></Title>
        </Col>
      </Row>

      <List
        dataSource={allDiaryQuestions}
        renderItem={(diaryQuestion, index) => (
          <List.Item key={index}>
            <Row gutter={12} style={{ width: "100%" }}>
              <Col span={10}>
                <strong>{diaryQuestion.question}</strong>
              </Col>
              <Col span={6}>{getQuestionTypeName(diaryQuestion.type)}</Col>
              <Col span={2}>{diaryQuestion.minUserXP}</Col>
              <Col span={2}>{diaryQuestion.maxUserXP}</Col>
              <Col span={2}>
                <Button type="primary" onClick={() => onEdit(diaryQuestion)}>
                  Bearbeiten
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  danger
                  type="primary"
                  onClick={() => onDelete(diaryQuestion.id)}
                >
                  <DeleteFilled style={{ width: 12 }} />
                </Button>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export default DiaryQuestionList;

import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

const { Title } = Typography;

const NotificationList = ({ allNotifications, onEdit, onDelete }) => {
	return (
		<>
			<Row gutter={[24, 0]} style={{ marginBottom: "10px" }}>
				<Col span={8}>
					<Title level={5}>Titel</Title>
				</Col>
				<Col span={8}>
					<Title level={5}>Inhalt</Title>
				</Col>
				<Col span={4}>
					<Title level={5}>Typ</Title>
				</Col>
				<Col span={4}>
					<Title level={5}></Title>
				</Col>
			</Row>

			<List
				dataSource={allNotifications}
				renderItem={(notification, index) => (
					<List.Item key={index}>
						<Row gutter={[24, 0]} style={{ width: "100%" }}>
							<Col span={8}>
								<strong>{notification.title}</strong>
							</Col>
							<Col span={8}>{notification.description}</Col>
							<Col span={4}>{notification.type}</Col>
							<Col span={4}>
								<Row gutter={12}>
									<Col>
										<Button type='primary' onClick={() => onEdit(notification)}>
											Bearbeiten
										</Button>
									</Col>
									<Col>
										<Button
											danger
											type='primary'
											onClick={() => onDelete(notification.id)}
										>
											<DeleteFilled style={{ width: 12 }} />
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		</>
	);
};

export default NotificationList;

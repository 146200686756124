import fetch from "../interceptor/fetchInterceptor";

export function addNotification(postData) {
	return fetch({
		url: "/notification",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function getNotification() {
	return fetch({
		url: "/notification",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}

export function updateNotification(id, postData) {
	return fetch({
		url: `/notification/${id}`,
		method: "put",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}

export function deleteNotification(id) {
	return fetch({
		url: `/notification/${id}`,
		method: "delete",
		headers: {
			"public-request": "true",
		},
	});
}

export function immediateNotification(postData) {
	return fetch({
		url: "/notification/immediate",
		method: "post",
		headers: {
			"public-request": "true",
		},
		data: postData,
	});
}
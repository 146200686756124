import React from "react";
import { Row, Col, Select, Input, Typography } from "antd";

const { Option } = Select;
const { Title } = Typography;

const CategorySelectionForTaskflow = ({
	selectedCategories,
	setSelectedCategories,
	categories,
	onlyToEarn = false,
}) => {
	const handleCategoryChange = (value) => {
		const updatedCategories = value
			.map((categoryId) => {
				const existingCategory = selectedCategories.find((cat) => cat.id === categoryId);
				const categoryObj = categories.find((cat) => cat.id === categoryId);
				return (
					existingCategory || {
						id: categoryId,
						title: categoryObj.title,
						min: 0,
						max: 0,
						pointsToEarn: 0,
					}
				);
			})
			.filter(Boolean);
		setSelectedCategories(updatedCategories);
	};

	const handleInputChange = (categoryId, type, value) => {
		const updatedCategories = selectedCategories.map((cat) =>
			cat.id === categoryId ? { ...cat, [type]: value } : cat
		);
		setSelectedCategories(updatedCategories);
	};

	return (
		<div>
			<Title level={5}>Kategorien</Title>
			<Select
				mode='multiple'
				placeholder='Kategorie auswählen'
				value={selectedCategories.map((cat) => cat.id)}
				onChange={handleCategoryChange}
				style={{ width: "100%", marginBottom: "10px" }}
			>
				{categories.map((category) => (
					<Option key={category.id} value={category.id}>
						{category.title}
					</Option>
				))}
			</Select>

			{selectedCategories.map((category) => (
				<Row key={category.id} gutter={16} style={{ marginBottom: "10px" }}>
					<Col span={6}>
						<span>{category.title}</span>
					</Col>
					{!onlyToEarn && (
						<>
							<Col span={6}>
								<Input
									type='number'
									addonBefore='Min Punkte'
									placeholder='Punkt-Untergrenze'
									value={category.min}
									onChange={(e) =>
										handleInputChange(
											category.id,
											"min",
											Number(e.target.value)
										)
									}
								/>
							</Col>
							<Col span={6}>
								<Input
									type='number'
									addonBefore='Max Punkte'
									placeholder='Punkt-Obergrenze'
									value={category.max}
									onChange={(e) =>
										handleInputChange(
											category.id,
											"max",
											Number(e.target.value)
										)
									}
								/>
							</Col>
						</>
					)}
					<Col span={onlyToEarn ? 12 : 6}>
						<Input
							type='number'
							addonBefore='Zu verdienende Punkte'
							placeholder='Points to earn'
							value={category.pointsToEarn}
							onChange={(e) =>
								handleInputChange(
									category.id,
									"pointsToEarn",
									Number(e.target.value)
								)
							}
						/>
					</Col>
				</Row>
			))}
		</div>
	);
};

export default CategorySelectionForTaskflow;

import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";

const { Title } = Typography;

const UserLevelList = ({ allUserLevel, onEdit, onDelete }) => {
	return (
		<>
			<Row gutter={[24, 0]} style={{ marginBottom: "10px" }}>
				<Col span={10}>
					<Title level={5}>User level</Title>
				</Col>
				<Col span={10}>
					<Title level={5}>erforderliche XP</Title>
				</Col>
				<Col span={2}>
					<Title level={5}></Title>
				</Col>
				<Col span={2}>
					<Title level={5}></Title>
				</Col>
			</Row>

			<List
				dataSource={allUserLevel}
				renderItem={(usrlevel, index) => (
					<List.Item key={index}>
						<Row gutter={[24, 0]} style={{ width: "100%" }}>
							<Col span={10}>
								<strong>{usrlevel.userLevel}</strong>
							</Col>
							<Col span={10}>{usrlevel.minXP}</Col>
							<Col span={2}>
								<Button type='primary' onClick={() => onEdit(usrlevel)}>
									Bearbeiten
								</Button>
							</Col>
							<Col span={2}>
								<Button danger type='primary' onClick={() => onDelete(usrlevel.id)}>
									<DeleteFilled style={{width: 12}} />
								</Button>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		</>
	);
};

export default UserLevelList;

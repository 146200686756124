// export default Notification;
import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	Input,
	Button,
	Typography,
	Select,
	message,
	TimePicker,
	DatePicker,
	Checkbox,
} from "antd";

import {
	addNotification,
	deleteNotification,
	getNotification,
	immediateNotification,
	updateNotification,
} from "../services/NotificationService";
import deDE from "antd/lib/locale/de_DE"; // Deutsche Locale für Ant Design
import { ConfigProvider } from "antd"; // Für Locale
import NotificationList from "../components/Notification/NotificationList";
import moment from "moment";
import "moment/locale/de"; // Deutsche Locale für Moment.js

// Setzt die globale Moment-Locale auf Deutsch
moment.locale("de");
const { Title } = Typography;
const { Option } = Select;

const Notification = () => {
	const [notificationData, setNotificationData] = useState({
		type: "Zeitabhängige Notification",
		title: "",
		description: "",
		additionalFields: {},
		notificationTime: "",
		notificationFrequency: "Daily",
		dayOfWeek: "",
		dateOfMonth: "",
	});
	const [allNotifications, setAllNotifications] = useState([]);
	const [isEditMode, setIsEditMode] = useState(false);
	const notificationTimeOptions = ["Daily", "Weekly", "Monthly"];
	const notificationTypes = [
		"Sofort-Notification",
		"Daily Reminder Notifications",
		// "Streak Reminder Notifications",
		// "XP Notifications",
		// "Ziel Notification",
		"Zeitabhängige Notification",
	];
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNotificationData({ ...notificationData, [name]: value });
	};
	const handleTimeChange = (value) => {
		setNotificationData({ ...notificationData, notificationTime: value });
	};
	const handleDayOfWeekChange = (value) => {
		setNotificationData({ ...notificationData, dayOfWeek: value });
	};

	const handleDateChange = (dateString) => {
		setNotificationData({
			...notificationData,
			dateOfMonth: dateString,
		});
	};
	const handleDropdownChange = (value) => {
		setNotificationData({
			type: value,
			title: "",
			description: "",
			additionalFields: {},
			notificationTime: "",
		});
	};
	const handleEdit = async (notification) => {
		setNotificationData({
			...notification,
			id: notification.id,
			notificationTime: notification.notificationTime
				? moment(notification.notificationTime)
				: null,
			dateOfMonth: notification.dateOfMonth ? moment(notification.dateOfMonth) : null,
		});
		setIsEditMode(true);
	};
	const handleDelete = async (id) => {
		await deleteNotification(id);
		message.success("Notification erfolgreich gelöscht");
		fetchAllNotifications();
	};
	const fetchAllNotifications = async () => {
		const res = await getNotification();
		setAllNotifications(res);
	};
	useEffect(() => {
		const fetchData = async () => {
			await fetchAllNotifications();

			if (notificationData.notificationFrequency === "Daily") {
				setNotificationData((prevData) => ({
					...prevData,
					notificationTime: moment(),
				}));
			}
		};
		fetchData();
	}, []);

	const renderTimePicker = () => (
		<TimePicker
			value={notificationData.notificationTime}
			format='HH:mm'
			onChange={handleTimeChange}
			style={{ width: "100%", marginBottom: "10px" }}
		/>
	);

	const renderAdditionalFields = () => {
		const { type, notificationFrequency } = notificationData;

		if (type === "Zeitabhängige Notification") {
			return (
				<>
					<Title level={5}>Notification Frequenz</Title>
					<Select
						placeholder='Frequenz wählen'
						style={{ width: "100%", marginBottom: "10px" }}
						onChange={(value) =>
							setNotificationData({
								...notificationData,
								notificationFrequency: value,
							})
						}
						value={notificationData.notificationFrequency}
					>
						{notificationTimeOptions.map((time) => (
							<Option key={time} value={time}>
								{time}
							</Option>
						))}
					</Select>

					{notificationFrequency === "Daily" && (
						<Row gutter={12}>
							<Col>{renderTimePicker()}</Col>
							<Col>
								<Checkbox
									checked={notificationData.sendAfterWakeupTime}
									onChange={(e) =>
										setNotificationData({
											...notificationData,
											sendAfterWakeupTime: e.target.checked,
										})
									}
								>
									After Wake up
								</Checkbox>
							</Col>
							<Col>
								<Checkbox
									checked={notificationData.sendBeforeBedTime}
									onChange={(e) =>
										setNotificationData({
											...notificationData,
											sendBeforeBedTime: e.target.checked,
										})
									}
								>
									Before Bedtime
								</Checkbox>
							</Col>
						</Row>
					)}

					{notificationFrequency === "Weekly" && (
						<>
							<Title level={5}>Tag in der Woche wählen</Title>
							<Row gutter={12}>
								<Col xs={6}>
									<Select
										value={notificationData.dayOfWeek}
										placeholder='Tag wählen'
										style={{ width: "100%", marginBottom: "10px" }}
										onChange={handleDayOfWeekChange}
									>
										{[
											"Monday",
											"Tuesday",
											"Wednesday",
											"Thursday",
											"Friday",
											"Saturday",
											"Sunday",
										].map((day) => (
											<Option key={day} value={day}>
												{day}
											</Option>
										))}
									</Select>
								</Col>
								<Col>{renderTimePicker()}</Col>
								<Col>
									<Checkbox
										checked={notificationData.sendAfterWakeupTime}
										onChange={(e) =>
											setNotificationData({
												...notificationData,
												sendAfterWakeupTime: e.target.checked,
											})
										}
									>
										After Wake up
									</Checkbox>
								</Col>
								<Col>
									<Checkbox
										checked={notificationData.sendBeforeBedTime}
										onChange={(e) =>
											setNotificationData({
												...notificationData,
												sendBeforeBedTime: e.target.checked,
											})
										}
									>
										Before Bedtime
									</Checkbox>
								</Col>
							</Row>
						</>
					)}

					{notificationFrequency === "Monthly" && (
						<>
							<Title level={5}>Tag wählen</Title>
							<Row gutter={12} justify='start' align='middle'>
								<Col>
									<DatePicker
										value={notificationData.dateOfMonth}
										onChange={handleDateChange}
										picker='date'
										style={{ width: "100%", marginBottom: "10px" }}
									/>
								</Col>
								<Col>{renderTimePicker()}</Col>
								<Col>
									<Checkbox
										checked={notificationData.sendAfterWakeupTime}
										onChange={(e) =>
											setNotificationData({
												...notificationData,
												sendAfterWakeupTime: e.target.checked,
											})
										}
									>
										After Wake up
									</Checkbox>
								</Col>
								<Col>
									<Checkbox
										checked={notificationData.sendBeforeBedTime}
										onChange={(e) =>
											setNotificationData({
												...notificationData,
												sendBeforeBedTime: e.target.checked,
											})
										}
									>
										Before Bedtime
									</Checkbox>
								</Col>
							</Row>
						</>
					)}
				</>
			);
		} else if (type === "Daily Reminder Notifications") {
			return (
				<>
					<Title level={5}>Message After Wake-Up</Title>
					<Input
						placeholder='Message After Wake-Up'
						name='messageAfterWakeUp'
						onChange={handleInputChange}
						style={{ marginBottom: "10px" }}
						value={notificationData.messageAfterWakeUp}
					/>
					<Title level={5}>Message Before Bed-Time</Title>
					<Input
						placeholder='Message Before Bed-Time'
						name='messageBeforeBedTime'
						onChange={handleInputChange}
						style={{ marginBottom: "10px" }}
						value={notificationData.messageBeforeBedTime}
					/>
				</>
			);
		}

		return null;
	};

	const handleSave = async () => {
		try {
			if (
				!notificationData.title ||
				(!notificationData.description &&
					notificationData.type !== "Daily Reminder Notifications")
			) {
				message.error("Titel und Inhalt dürfen nicht leer sein.");
				return;
			}

			if (notificationData.daysInRow) {
				notificationData.daysInRow = +notificationData.daysInRow;
			}
			if (notificationData.xpThreshold) {
				notificationData.xpThreshold = +notificationData.xpThreshold;
			}
			delete notificationData.additionalFields;
			if (isEditMode) {
				await updateNotification(notificationData.id, notificationData);
				message.success("Notification erfolgreich aktualisiert");
				fetchAllNotifications();
			} else if (notificationData.type === "Sofort-Notification") {
				console.log("notificationData=  ",notificationData);
				await immediateNotification({title: notificationData.title, description: notificationData.description});
				message.success("Notification erfolgreich gesendet");
			} else {
				
				await addNotification(notificationData);
				message.success("Notification erfolgreich hinzugefügt");
				fetchAllNotifications();
			}
			setNotificationData({
				title: "",
				description: "",
				additionalFields: {},
				notificationTime: "",
			});
		} catch (error) {
			message.error("Es ist ein Fehler beim Speichern aufgetreten");
			console.error(error);
		}
	};
	return (
		<ConfigProvider locale={deDE}>
			<div className='layout-content'>
				<Row gutter={[24, 0]}>
					<Col xs={24}>
						<Card bordered={false} className='criclebox'>
							<Title level={3}>Push Notification Editor</Title>
							<Select
								value={notificationData.type}
								placeholder='Select Notification Type'
								style={{ width: "100%", marginBottom: "10px" }}
								onChange={handleDropdownChange}
							>
								{notificationTypes.map((type) => (
									<Option key={type} value={type}>
										{type}
									</Option>
								))}
							</Select>
							<Title level={5}>Titel</Title>
							<Input
								placeholder='Titel'
								name='title'
								value={notificationData.title}
								onChange={handleInputChange}
								style={{ marginBottom: "10px" }}
							/>
							{notificationData.type !== "Daily Reminder Notifications" && (
								<>
									<Title level={5}>Inhalt</Title>
									<Input.TextArea
										placeholder='Inhalt'
										name='description'
										value={notificationData.description}
										onChange={handleInputChange}
										style={{ marginBottom: "10px" }}
									/>
								</>
							)}
							{renderAdditionalFields()}
							<Button
								type='primary'
								onClick={handleSave}
								block
								style={{ marginTop: "20px" }}
							>
								{notificationData.type === "Sofort-Notification"
									? "Senden"
									: "Speichern"}
							</Button>
						</Card>
					</Col>
				</Row>
				<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
					<Col xs={24}>
						<Card bordered={false} className='criclebox'>
							<NotificationList
								allNotifications={allNotifications}
								onEdit={handleEdit}
								onDelete={handleDelete}
							/>
						</Card>
					</Col>
				</Row>
			</div>
		</ConfigProvider>
	);
};
export default Notification;

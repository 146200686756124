import React, { useState, useEffect } from "react";
import { Table, Button, Input, Row, Col } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";

const BusinessRuleList = ({
  businessRules,
  onEdit,
  onDelete,
  operatorReverseMap,
  fieldReverseMap,
  typeReverseMap,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredBusinessRules, setFilteredBusinessRules] =
    useState(businessRules);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setFilteredBusinessRules(businessRules);
  }, [businessRules]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = businessRules.filter(
      (rule) =>
        rule.title.toLowerCase().includes(value) ||
        fieldReverseMap[rule.conditions[0]?.field]
          .toLowerCase()
          .includes(value) ||
        operatorReverseMap[rule.conditions[0]?.operator]
          .toLowerCase()
          .includes(value)
    );
    setFilteredBusinessRules(filteredData);
    setCurrentPage(1);
  };

  const renderDiaryArchiveActionValue = (value) => {
    const parsedValue = JSON.parse(value);

    return (
      <div>
        <div>Stimmung: {parsedValue.mood}</div>
        <div>Tags: {parsedValue.tags.join(", ")}</div>
      </div>
    );
  };
  const renderShowContentValue = (value) => {

    let tagsToDisplay = value;

    // check if value is json and parse if it is
    if (typeof value === "string") {
      try {
        tagsToDisplay = JSON.parse(value).tags.join(", ");
      } catch (e) {
        console.error("Error parsing JSON", e);
      }
    }

    return (
      <div>
        <div>{tagsToDisplay}</div>
      </div>
    );
  };

  const renderCategoryActionValue = (value) => {
    return <div>{JSON.parse(value).title}</div>;
  };
  const renderTaskflowActionValue = (value) => {
    let dataToDisplay = value;

    // check if value is json and parse if it is
    if (typeof value === "string") {
      try {
        dataToDisplay = JSON.parse(value)?.value;
      } catch (e) {
        console.error("Error parsing JSON", e);
      }
    }

    return <div>{dataToDisplay}</div>;
  };

  const renderActionValue = (value, record) => {
    if (record.actions[0]?.type === "SHOW_DIARY_ENTRY_FROM_ARCHIVE") {
      return renderDiaryArchiveActionValue(value);
    } else if (record.actions[0]?.type === "TASKFLOW_BY_CATEGORY") {
      return renderCategoryActionValue(value);
    } else if (record.actions[0]?.type === "TASKFLOW") {
      return renderTaskflowActionValue(value);
    } else if (record.actions[0]?.type === "SHOW_CONTENT") {
      return renderShowContentValue(value);
    } else {
      return value;
    }
  };

  const columns = [
    {
      title: "Bezeichnung",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 200,
    },
    {
      title: "Feld",
      dataIndex: ["conditions", 0, "field"],
      key: "field",
      render: (field) => fieldReverseMap[field],
      width: 150,
    },
    {
      title: "Operator",
      dataIndex: ["conditions", 0, "operator"],
      key: "operator",
      render: (operator) => operatorReverseMap[operator],
      width: 100,
    },
    {
      title: "Wert",
      dataIndex: ["conditions", 0],
      key: "conditionValue",
      render: (condition) =>
        condition.field === "ACTIVITY"
          ? condition.activityValues.join(", ")
          : condition.value,
      width: 150,
    },
    {
      title: "Action Typ",
      dataIndex: ["actions", 0, "type"],
      key: "actionType",
      render: (type) => typeReverseMap[type],
      width: 150,
    },
    {
      title: "Wert",
      dataIndex: ["actions", 0, "value"],
      key: "actionValue",
      render: (value, record) => renderActionValue(value, record),
      width: 250,
    },
    {
      title: "Aktionen",
      key: "actions",
      width: 150,
      render: (_, businessRule) => (
        <>
          <Button type="primary" onClick={() => onEdit(businessRule)}>
            Bearbeiten
          </Button>
          <Button
            danger
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => onDelete(businessRule.id)}
          >
            <DeleteFilled style={{ width: 12 }} />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col xs={8}>
          <Input
            placeholder="Suche nach Bezeichnung, Feld oder Operator"
            value={searchText}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredBusinessRules}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} von ${total} Einträgen`,
          locale: {
            items_per_page: "/ Seite",
          },
        }}
        rowKey={(rule) => rule.id}
        scroll={{ x: 1000 }}
      />
    </>
  );
};

export default BusinessRuleList;

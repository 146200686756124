import React from "react";
import { List, Button, Row, Col, Typography } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { BASE_CATEGORIES } from "../../config/constants/categories";

const { Title } = Typography;

const CategoryList = ({ categories, onEdit, onDelete }) => {
	return (
		<>
			<Row gutter={[24, 0]} style={{ marginBottom: "10px" }}>
				<Col span={8}>
					<Title level={5}>Kategorie Name</Title>
				</Col>
				<Col span={6}>
					<Title level={5}>Kategorie Beschreibung</Title>
				</Col>
				<Col span={4}>
					<Title level={5}>Basis Kategorie</Title>
				</Col>
				<Col span={2}>
				</Col>
				<Col span={2}>
				</Col>
			</Row>

			<List
				dataSource={categories}
				renderItem={(category, index) => (
					<List.Item key={index}>
						<Row gutter={[24, 0]} style={{ width: "100%" }}>
							<Col span={8}>
								<strong>{category.title}</strong>
							</Col>
							<Col span={6}>{category.description || "No description"}</Col>
							<Col span={4}>
								{BASE_CATEGORIES.find(el => el.key === category.baseCategory).name}
							</Col>
							{/* <Col span={2}>{category.level}</Col>
							<Col span={2}>{category.categoryPoints}</Col> */}
							<Col span={2}>
								<Button type='primary' onClick={() => onEdit(category)}>
									Bearbeiten
								</Button>
							</Col>
							<Col span={2}>
								<Button danger type='primary' onClick={() => onDelete(category.id)} >
									<DeleteFilled style={{width: 12}} />
								</Button>
							</Col>
						</Row>
					</List.Item>
				)}
			/>
		</>
	);
};

export default CategoryList;
